import React, { useEffect, useState } from 'react';
import Text from '../components/common/text/Text';
import BaseButton from '../components/common/button/BaseButton';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { ModalType, showModal, showModalWithRequest } from '../store/slices/modalSlice';
import { TextStyles } from '../styles/textStyles';
import useDevice from '../hooks/useDevice';
import Footer from '../components/template/footer/Footer';
import sampleVideo from '../assets/video/sampleVideo.mp4';
import mobileSampleVideo from '../assets/video/sampleVideo_mobile.mp4';
import { useAuthContext } from '../hooks/AuthContext';
import LobbyBanners from '../components/template/settings/LobbyBanners';
import { Tab, TabContainer } from '../components/template/settings/MenuTab';
import TableListTemplate from '../components/template/settings/TableListTemplate';
import { LobbyTableList } from '../store/api/responseTypes';
import LandingFAQ from '../components/template/landing/LandingFAQ';
import YellowCardImg from 'src/assets/img/Card-Yellow.png';
import PurpleCardImg from 'src/assets/img/Card-Purple.png';
import OrangeCardImg from 'src/assets/img/Card-Orange.png';
import NavyCardImg from 'src/assets/img/Card-Navy.png';
import GreenCardImg from 'src/assets/img/Card-Green.png';
import { useLazyLobbyTableListQuery } from '../store/api/holdem';
import { logError } from '../utils/ConsoleUtil';
import { useTranslation } from 'react-i18next';
import useLangNavigate from '../hooks/useLangNavigate';
import i18n from '../i18n';
import TournamentListTemplate from '../components/template/tournament/TournamentListTemplate';
import { RootState } from '../store/store';
import { selectTournaments } from '../store/slices/tournamentSlice';
import { useLazyTournamentListQuery } from '../store/api/tournament';
import TournamentCommingSoon from '../components/template/tournament/TournamentCommingSoon';

const LandingScreen = () => {
  const { isAuthorized } = useAuthContext();
  const { isMobile, isDesktop, isDesktopSmall } = useDevice();
  const [activeTab, setActiveTab] = React.useState<'CASHGAME' | 'TOURNAMENT'>('CASHGAME');
  const [tableList, setTableList] = useState<LobbyTableList | undefined>(undefined);
  // const tournaments = useSelector((state: RootState) => selectTournaments(state));
  // const [selectedTournamentId, setSelectedTournamentId] = useState<number | undefined>(undefined);
  // const [fetchTournamentList, { isFetching }] = useLazyTournamentListQuery();
  const dispatch = useDispatch();
  const navigate = useLangNavigate();
  const [refetchTableList] = useLazyLobbyTableListQuery(undefined);

  const { t: translation } = useTranslation();

  useEffect(() => {
    // 데이터 fetch 함수
    const fetchData = () => {
      refetchTableList()
        .unwrap()
        .then(list => {
          setTableList(list?.data);
        })
        .catch(error => {
          logError('Failed to fetch table list:', error);
        });
    };
    // 초기 데이터 fetch
    fetchData();
    // 10초마다 데이터 fetch
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);
    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(intervalId);
  }, [refetchTableList]);

  // useEffect(() => {
  //   fetchTournamentList({ limit: 20, order: 'DESC', orderBy: 'START' }).unwrap();
  // }, []);
  //
  // useEffect(() => {
  //   if (!selectedTournamentId && tournaments.length > 0) {
  //     setSelectedTournamentId(tournaments[0].id);
  //   }
  // }, [tournaments]);

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const showCreateTableModal = () => dispatch(showModalWithRequest({ type: ModalType.CreateTableModal, data: { chip: undefined } }));

  const goLobby = () => {
    navigate('/cashgame');
  };

  const onCreateTableClick = () => {
    if (!isAuthorized) {
      showAuthModal();
      return;
    }
    navigate('/cashgame');
    showCreateTableModal();
  };

  return (
    <Background>
      <StyledInner>
        <StyledTitle>
          <StartText breakType={'word'} fontStyle={'DisplayXl_Semibold'} textAlign={'start'} text={`${translation('LANDING.MainTitle', { newline: '\\n' })}`} />
          <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextLg_Medium'} text={`${translation('LANDING.MainDescription', { newline: isMobile ? '' : '\\n' })}`} textAlign={'start'} />
          <StyledButtons>
            {isAuthorized && <StartButton w={isMobile ? 120 : 180} size={isMobile ? 'btn44' : 'btn60'} color="gray" block={false} onClick={onCreateTableClick} text={translation('BUTTON.CreateTable')} textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'} />}
            <StartButton
              w={isMobile ? 120 : 180}
              size={isMobile ? 'btn44' : 'btn60'}
              color="primary"
              block={false}
              onClick={() => {
                isAuthorized ? goLobby() : showAuthModal();
              }}
              text={isAuthorized ? translation('BUTTON.PlayNow') : translation('BUTTON.GetStarted')}
              textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'}
            />
          </StyledButtons>
        </StyledTitle>
        <ContentContainer>
          <Video autoPlay loop muted playsInline key={isMobile ? mobileSampleVideo : sampleVideo}>
            <source src={isMobile ? mobileSampleVideo : sampleVideo} type={'video/mp4'} />
            Your browser does not support the video tag.
          </Video>
        </ContentContainer>
      </StyledInner>
      <StyledBanner>
        <StyledBannerTitle type={'promotion'} fontStyle={'DisplaySm_Semibold'} text={translation('PROMOTIONS.PromotionHeader')} textAlign={'start'} />
        <LobbyBanners type={'landing'} />
      </StyledBanner>
      <StyledLobby>
        <StyledLobbyInner>
          <StyledLobbyLinks>
            <StyledLobbySection>
              <StyledLobbyBanner>
                <img src={require('src/assets/img/landing-cashgame.png')} />
              </StyledLobbyBanner>
              <StyledLinkTitle textAlign={'start'} fontStyle={'DisplayXs_Semibold'} text={translation('LANDING.CreateGame')} marginBottom={8} marginTop={24} />
              <Text textAlign={'start'} marginBottom={24} lineHeight={isMobile ? 20 : 24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={translation('LANDING.PlayPoker')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
              <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={translation('LANDING.CreateGame')} onClick={onCreateTableClick} />
            </StyledLobbySection>
            <StyledLobbySection>
              <StyledLobbyBanner>
                <img src={require('src/assets/img/landing-tournament.png')} />
              </StyledLobbyBanner>
              <StyledLinkTitle textAlign={'start'} fontStyle={'DisplayXs_Semibold'} text={translation('LANDING.PlayPokerJoin')} marginBottom={8} marginTop={24} />
              <Text textAlign={'start'} marginBottom={24} lineHeight={isMobile ? 20 : 24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={translation('LANDING.PlayPokerDes')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
              <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={translation('LANDING.PlayPokerJoin')} onClick={goLobby} style={{ marginTop: 'auto' }} />
            </StyledLobbySection>
          </StyledLobbyLinks>
          <StyledLobbyList>
            <StyledTabs>
              <TabContainer bgColor={'gray800'}>
                <Tab onClick={() => setActiveTab('CASHGAME')} aria-selected={activeTab === 'CASHGAME'}>
                  {translation('LANDING.CashGames')}
                </Tab>
                <Tab onClick={() => setActiveTab('TOURNAMENT')} aria-selected={activeTab === 'TOURNAMENT'}>
                  {translation('LANDING.Tournaments')}
                </Tab>
              </TabContainer>
            </StyledTabs>
            <Content>
              {activeTab === 'CASHGAME' ? (
                <TableListTemplate type={'landing'} assetFilter={'ALL'} tableList={tableList} />
              ) : (
                // <TournamentListTemplate type={'landing'} tournamentList={tournaments} setSelectedTournament={setSelectedTournamentId} onCreateButtonClick={onCreateTableClick} selectedTournamentId={selectedTournamentId} />
                <TournamentCommingSoon />
              )}
            </Content>
          </StyledLobbyList>
        </StyledLobbyInner>
      </StyledLobby>
      <StyledFeatures>
        <StyledFeatureTitle>
          <StyledBannerTitle type={'feature'} fontStyle={'DisplaySm_Semibold'} textAlign={'start'} text={translation('LANDING.WhyBasepoker')} />
          <BaseButton
            size={isMobile ? 'btn44' : 'btn60'}
            textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'}
            color={'primary'}
            w={isMobile ? 120 : 220}
            onClick={() => {
              isAuthorized ? goLobby() : showAuthModal();
            }}
            text={isAuthorized ? translation('BUTTON.PlayNow') : translation('BUTTON.GetStarted')}
          />
        </StyledFeatureTitle>
        <StyledFeatureList>
          <StyledFeatureSection>
            <StyledTopSection>
              <StyledPlayBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={isMobile ? 'TextMd_Semibold' : isDesktopSmall ? 'TextLg_Semibold' : 'TextXl_Semibold'} text={translation('LANDING.PlayNowHead1')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes1')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <StyledPlayImage>
                  <img src={require('src/assets/img/landing-play.png')} />
                </StyledPlayImage>
              </StyledPlayBox>
              {!isDesktop && (
                <StyledSupportSection>
                  <StyledCreateBox>
                    <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.PlayNowHead2')} textColor={'gray900'} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                    <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes2')} textColor={'gray900'} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                    <StyledAccountImage>
                      <img src={require('src/assets/img/landing-accounts.png')} />
                    </StyledAccountImage>
                  </StyledCreateBox>
                  <StyledSupportBox>
                    <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.PlayNowHead3')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                    <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes3')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                    <StyledSupportImage>
                      <img src={require('src/assets/img/landing-support.png')} />
                    </StyledSupportImage>
                  </StyledSupportBox>
                </StyledSupportSection>
              )}
            </StyledTopSection>
            <StyledBottomSection>
              <StyledCryptoBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.PlayNowHead4')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes4')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <StyledCryptoImage>
                  <img src={require('src/assets/img/landing-crypto.png')} />
                </StyledCryptoImage>
              </StyledCryptoBox>
              {!isDesktop && (
                <StyledFairBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.RandomAndFair')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.OurRng')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <StyledFairImage>
                    <img src={require('src/assets/img/landing-fair.png')} />
                  </StyledFairImage>
                </StyledFairBox>
              )}
            </StyledBottomSection>
          </StyledFeatureSection>
          {isDesktop && (
            <StyledFeatureSection>
              <StyledSupportSection>
                <StyledCreateBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.PlayNowHead2')} textColor={'gray900'} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes2')} textColor={'gray900'} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <StyledAccountImage>
                    <img src={require('src/assets/img/landing-accounts.png')} />
                  </StyledAccountImage>
                </StyledCreateBox>
                <StyledSupportBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.PlayNowHead3')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.PlayNowDes3')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                  <StyledSupportImage>
                    <img src={require('src/assets/img/landing-support.png')} />
                  </StyledSupportImage>
                </StyledSupportBox>
              </StyledSupportSection>
              <StyledFairBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={translation('LANDING.RandomAndFair')} marginBottom={8} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('LANDING.OurRng')} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
                <StyledFairImage>
                  <img src={require('src/assets/img/landing-fair.png')} />
                </StyledFairImage>
              </StyledFairBox>
            </StyledFeatureSection>
          )}
        </StyledFeatureList>
      </StyledFeatures>
      <LandingFAQ />
      <Footer />
    </Background>
  );
};

const StyledFeatureTitleText = styled(Text)`
  @media (max-width: 1024px) {
    ${({ theme }) => theme.textStyles.TextLg_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
  }
`;
const StyledLinkTitle = styled(Text)`
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.DisplayXs_Semibold};
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXl_Semibold};
  }
  @media (max-width: 1024px) {
    ${({ theme }) => theme.textStyles.TextLg_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
  }
`;
const StyledBottomSection = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const StyledTopSection = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledPlayImage = styled.div`
  max-width: 446px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  flex: 1;
  margin-top: 32px;
  @media (max-width: 1280px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledFairImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 290px;
    height: auto;
  }
`;
const StyledSupportImage = styled.div`
  width: 218px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledAccountImage = styled.div`
  width: 176px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledCryptoImage = styled.div`
  width: 280px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 24px;
    max-width: 280px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledBox = styled.div`
  width: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  border-radius: 12px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 24px;
  }
`;
const StyledFairBox = styled(StyledBox)`
  height: 472px;
  position: relative;
  background: linear-gradient(180deg, #ff7f3a 0%, #ec4a0a 100%), url(${OrangeCardImg});
  background-position: bottom;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 100%;
  }
`;
const StyledSupportBox = styled(StyledBox)`
  height: auto;
  position: relative;
  background: linear-gradient(180deg, #6172f3 0%, #3538cd 100%), url(${PurpleCardImg});
  background-position: bottom right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    width: 100%;
    height: 100%;
  }
`;
const StyledCreateBox = styled(StyledBox)`
  height: auto;
  position: relative;
  background: linear-gradient(180deg, #fef0c7 0%, #fdb022 100%), url(${YellowCardImg});
  background-position: top right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    width: 100%;
    height: 100%;
  }
  //@media (max-width: 1024px) {
  //  height: 246px;
  //}
  //@media (max-width: 768px) {
  //  height: 200px;
  //}
`;
const StyledSupportSection = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const StyledCryptoBox = styled(StyledBox)`
  height: 292px;
  position: relative;
  background: linear-gradient(180deg, #018f69 0%, #1b6954 100%), url(${GreenCardImg});
  background-position: bottom right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 446px;
  }
  @media (max-width: 1024px) {
    height: 270px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const StyledPlayBox = styled(StyledBox)`
  height: auto;
  align-items: center;
  padding: 32px;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(180deg, #2d3748 0%, #1d2839 100%), url(${NavyCardImg});
  background-position: bottom;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 600px;
    flex: 2;
  }
`;
const StyledFeatureSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: 1280px) {
    gap: 16px;
  }
`;
const StyledFeatureList = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1280px) {
    gap: 16px;
  }
`;
const StyledFeatureTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }
`;
const StyledFeatures = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-width: 1200px;
  @media (max-width: 1280px) {
    gap: 16px;
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 560px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: max-content;
    height: 400px;
  }
`;
const StyledTabs = styled.div`
  width: 320px;
`;
const StyledLobbyLinks = styled.div`
    display: flex;
    box-sizing: border-box;
    gap: 40px;
    @media (max-width: 1024px) {
        gap: 24px;
        padding: 0 40px;
    }
    @media (max-width: 768px) {
        gap: 32px;
        padding: 0 20px;
        flex-direction: column;
`;
const StyledLobbyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray900};
  box-sizing: border-box;
  padding: 24px;
  padding-bottom: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;
const StyledLobbyBanner = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

const StyledLobbySection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: start;
`;

const StyledLobbyInner = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 90px;
`;
const StyledLobby = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray800};
  width: 100%;
  box-sizing: border-box;
  padding: 90px 40px 0 40px;
  @media (max-width: 1280px) {
    padding: 80px 40px 0 40px;
  }
  @media (max-width: 1024px) {
    padding: 80px 0 0 0;
  }
`;

const StyledBanner = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
`;
const StyledBannerTitle = styled(Text)<{ type: 'promotion' | 'feature' }>`
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.DisplayXs_Semibold};
  }
  @media (max-width: 1280px) {
    margin: ${({ type }) => (type === 'promotion' ? '0 40px' : '0')};
    ${({ theme }) => theme.textStyles.TextXl_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: ${({ type }) => (type === 'promotion' ? '0 20px' : '0')};
  }
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  max-width: 1400px;
  box-sizing: border-box;

  //padding: 0 96px;
  @media (max-width: 1600px) {
    padding: 0 40px;
    max-width: 1480px;
  }
  @media (max-width: 1400px) {
    padding: 0 40px;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    padding: 0 40px;
    flex-direction: column;
    align-items: start;
    gap: 40px;
  }

  @media (max-width: 768px) {
    gap: 12px;
    padding: 0 20px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const Background = styled.div`
  background: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
  padding-top: 212px;
  gap: 90px;
  box-sizing: border-box;
  min-height: 100vh;
  @media (max-width: 1280px) {
    padding-top: 172px;
  }
  @media (max-width: 768px) {
    padding-top: 100px;
    height: auto;
    flex: 1;
    box-sizing: border-box;
    gap: 40px;
  }
`;

const StartText = styled(Text)<{
  fontStyle: keyof typeof TextStyles;
}>`
  ${({ theme, fontStyle }) => theme.textStyles[fontStyle]};
  background: linear-gradient(90deg, #fff 0%, #fff 52.75%, #999 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 1280px) {
    ${({ fontStyle }) => TextStyles['DisplayLg_Semibold']};
  }
  @media (max-width: 768px) {
    text-align: start;
    ${({ fontStyle }) => TextStyles['DisplaySm_Semibold']};
  }
`;

const StartButton = styled(BaseButton)`
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 7px;
    align-self: start;
  }
`;

const ContentContainer = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  color: white;

  width: 750px;
  height: auto;

  @media (max-width: 1280px) {
    width: 560px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 12px;
    flex: inherit;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export default LandingScreen;
