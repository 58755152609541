import React from 'react';
import styled from '@emotion/styled';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import HorizontalLayout from '../../layout/HorizontalLayout';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../../common/text/Text';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { toFixedFloor } from '../../../utils/StringUtil';
import { TournamentStatus } from '../../../store/slices/tournamentTypes';

const TournamentStateStatus: React.FC = () => {
  const { tournamentInfo, tournamentSummary, tournamentStructure } = useTournamentLobby();

  const getStackStatus = () => {
    switch (tournamentInfo?.dynamic?.status) {
      case TournamentStatus.CREATED:
      case TournamentStatus.PREPARING:
      case TournamentStatus.REGISTERING:
        return <TitleValueHorizontal label={'Starting Chips'} value={toFixedFloor(tournamentInfo?.static?.startingChips, 0, false, true)} labelColor={'gray400'} valueColor={'gray300'} />;
      case TournamentStatus.LATE_REGISTRATION:
      case TournamentStatus.RUNNING:
      case TournamentStatus.CLOSED:
      default:
        return (
          <>
            <TitleValueHorizontal label={'Smallest'} value={toFixedFloor(tournamentSummary?.stackStatus?.stacks?.smallest, 0, false, true)} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Average'} value={toFixedFloor(tournamentSummary?.stackStatus?.stacks?.average, 0, false, true)} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Largest'} value={toFixedFloor(tournamentSummary?.stackStatus?.stacks?.largest, 0, false, true)} labelColor={'gray400'} valueColor={'gray300'} />
          </>
        );
    }
  };

  const getPlayerStatus = () => {
    switch (tournamentInfo?.dynamic?.status) {
      case TournamentStatus.CREATED:
      case TournamentStatus.PREPARING:
      case TournamentStatus.REGISTERING:
        return <TitleValueHorizontal label={'Entries'} value={toFixedFloor(tournamentInfo?.dynamic?.entrantCount, 0, false, false)} labelColor={'gray400'} valueColor={'gray300'} />;
      case TournamentStatus.LATE_REGISTRATION:
      case TournamentStatus.RUNNING:
      case TournamentStatus.CLOSED:
        return (
          <>
            <TitleValueHorizontal label={'Players Left'} value={toFixedFloor(tournamentSummary?.playerStatus?.live, 0, false, false)} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Unique Entries'} value={toFixedFloor(tournamentSummary?.playerStatus?.unique, 0, false, false)} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Re-entries'} value={toFixedFloor(tournamentSummary?.playerStatus?.reEntry, 0, false, false)} labelColor={'gray400'} valueColor={'gray300'} />
          </>
        );
    }
    return null;
  };

  return (
    <TournamentDetailBox>
      <Wrap>
        <LinearLayout gap={4}>
          <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Stack Status'} textAlign={'start'} marginBottom={8} />
          {getStackStatus()}
        </LinearLayout>
        <LinearLayout gap={4}>
          {getPlayerStatus() !== null && (
            <>
              <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Player Status'} textAlign={'start'} marginBottom={8} />
              {getPlayerStatus()}
            </>
          )}
        </LinearLayout>
      </Wrap>
    </TournamentDetailBox>
  );
};

const Wrap = styled.div`
  padding: 24px;
  gap: 24px;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    padding: 0 16px 16px 16px;
  }
`;

export default TournamentStateStatus;
