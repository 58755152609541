import React from 'react';
import useDevice from 'src/hooks/useDevice';
import ShowCard from 'src/components/common/card/ShowCard';
import { AuctionButtonsContainer, ButtonContainer, CardWrap, ShowCardWrap, StyledActionButton, StyledSeatButton, StyledShowCardButton } from './styles';
import useTableActions from '../../../../hooks/useTableActions';
import Presets from './Presets';
import PreActions from './PreActions';
import { useTableHandActions } from '../../../../hooks/useTableHandActions';
import { SeatStatus } from '../../../../store/api/responseTypes';
import { useTableContext } from '../../../../hooks/TableProvider';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import useRouteMatch from '../../../../hooks/useRouteMatch';

const ActionButtons = () => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const { isCashGame } = useRouteMatch();
  const { isMyTurn, myUserTotalStack, mySeatStatus, isTournamentTable } = useTableContext();
  const { bet, betForRaise, minBetForRaise, handleBetChange, minRaise, maxRaise, isPresetOpen, reservedAction, getAllowedActions, getActionTitle, onReservedActionClick, onActionClick } = useTableHandActions();
  const { canBuyIn, availableSeats, myCards, canSeatBackIn, seatBackIn, canReserve, canShowCards, showCards, showVerifyPasswordModal, showBuyIn } = useTableActions();

  const renderShowCardButtons = () => {
    if (!myCards) {
      return <></>;
    }
    return (
      <StyledShowCardWrap>
        <StyledShowCardButton size={isMobile ? 'btn48' : 'btn60'} w={160} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([0])}>
          <ShowCardWrap>
            {t('ACTIONS.Show')}
            <CardWrap>
              <ShowCard card={myCards[0]} />
            </CardWrap>
          </ShowCardWrap>
        </StyledShowCardButton>
        <StyledShowCardButton size={isMobile ? 'btn48' : 'btn60'} w={160} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([1])}>
          <ShowCardWrap>
            {t('ACTIONS.Show')}
            <CardWrap>
              <ShowCard card={myCards[1]} />
            </CardWrap>
          </ShowCardWrap>
        </StyledShowCardButton>
        <StyledShowCardButton size={isMobile ? 'btn48' : 'btn60'} w={160} minWidth={130} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([0, 1])}>
          <ShowCardWrap>
            {t('ACTIONS.Show')}
            <CardWrap>
              <ShowCard card={myCards[0]} />
              <ShowCard card={myCards[1]} />
            </CardWrap>
          </ShowCardWrap>
        </StyledShowCardButton>
      </StyledShowCardWrap>
    );
  };

  return (
    <AuctionButtonsContainer>
      {isMyTurn && !reservedAction && isPresetOpen && <Presets minBetForRaise={minBetForRaise} betAmount={betForRaise} setBetAmount={handleBetChange} minRaise={minRaise} maxRaise={maxRaise} onCall={() => onActionClick('CALL')} onBetting={() => onActionClick('BET')} />}
      <ButtonContainer>
        {(mySeatStatus === SeatStatus.SEAT_IN || !isCashGame) &&
          isMyTurn &&
          !reservedAction &&
          getAllowedActions().map((action, i) => (
            <StyledActionButton key={i} shadow="button" color="actionRed" textStyle={isMobile ? 'TextMd_Bold' : 'DisplayXs_Bold'} textColor="white" size={isMobile ? 'btn48' : 'btn90'} text={getActionTitle(action, false)} onClick={() => onActionClick(action)} block={true} />
          ))}
        {isCashGame && mySeatStatus === SeatStatus.SEAT_IN && !isMyTurn && getAllowedActions().length > 0 && <PreActions getActions={getAllowedActions} getActionText={action => getActionTitle(action, true)} handleSelect={onReservedActionClick} selectedAction={reservedAction} isMobile={isMobile} />}
        {isCashGame && canSeatBackIn && <StyledActionButton shadow="button" color="actionRed" size={isMobile ? 'btn48' : 'btn90'} onClick={seatBackIn} w={264} textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'} block={isMobile} textColor="white" text={t('ACTIONS.SitBackIn')} />}
        {isCashGame && canBuyIn && (
          <StyledActionButton
            shadow="button"
            color="actionRed"
            size={isMobile ? 'btn48' : 'btn90'}
            onClick={() => {
              showBuyIn();
            }}
            w={264}
            textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'}
            block={isMobile}
            textColor="white"
            text={myUserTotalStack ? `${t('ACTIONS.AddChips')}` : `${t('ACTIONS.BuyIn')}`}
          />
        )}
        {isCashGame && canReserve && (
          <StyledSeatButton
            shadow="button"
            color="actionRed"
            size={isMobile ? 'btn48' : 'btn90'}
            onClick={() => {
              showVerifyPasswordModal();
            }}
            w={284}
            textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'}
            block={isMobile}
            textColor="white"
            text={availableSeats === 1 ? `${t('ACTIONS.TakeSeat')}` : `${t('ACTIONS.SeatMeAnywhere')}`}
          />
        )}
        {canShowCards && myCards && renderShowCardButtons()}
      </ButtonContainer>
    </AuctionButtonsContainer>
  );
};

const StyledShowCardWrap = styled.div`
  display: flex;
  min-width: 616px;
  gap: 8px;
  @media (max-width: 1440px) {
    min-width: 496px;
  }
  @media (max-width: 1280px) {
    min-width: 406px;
  }
  @media (max-width: 768px) {
    width: 100%;
    min-width: 0;
  }
`;

export default ActionButtons;
