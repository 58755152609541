import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { TournamentTables } from '../../../store/slices/tournamentTypes';
import TournamentTablePreview from './TournamentTablePreview';
import { numberToDisplayString } from '../../../utils/StringUtil';
import useOpenTable from '../../../hooks/useOpenTable';
import useDevice from '../../../hooks/useDevice';

const TournamentDetailTabTables: React.FC = () => {
  const { tournamentId, tournamentTables } = useTournamentLobby();
  const [selectedTable, setSelectedTable] = useState<TournamentTables>();
  const { openTable } = useOpenTable();
  const { isMobile } = useDevice();

  useEffect(() => {
    setSelectedTable(tournamentTables?.[0]);
  }, []);

  const renderRow = (tournamentTable: TournamentTables) => (
    <TournamentTablesRow
      key={tournamentTable.shareCode}
      selected={tournamentTable.shareCode === selectedTable?.shareCode}
      onClick={() => {
        setSelectedTable(tournamentTable);
      }}
      onDoubleClick={() => openTable(tournamentTable.shareCode, tournamentId)}
    >
      <TournamentTablesCell color={'white'}>{numberToDisplayString(tournamentTable.tableId)}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{numberToDisplayString(tournamentTable.playersCnt)}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{numberToDisplayString(tournamentTable?.stack?.largest)}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{numberToDisplayString(tournamentTable?.stack?.average)}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{numberToDisplayString(tournamentTable?.stack?.smallest)}</TournamentTablesCell>
    </TournamentTablesRow>
  );

  return (
    <TournamentTablesContainer>
      <TableSection maxHeight={256}>
        <StyledTableHeader>
          <TournamentTablesHeader>
            <TournamentTablesCell color={'gray400'}>Table</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Player</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Largest Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Average Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Smallest Stack</TournamentTablesCell>
          </TournamentTablesHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentTables?.map(renderRow)}</StyledTableBody>
      </TableSection>
      <StyledDivider />
      {tournamentId && selectedTable?.shareCode && (
        <TableSection>
          <TournamentTablePreview tournamentId={tournamentId} shareCode={selectedTable?.shareCode} />
        </TableSection>
      )}
    </TournamentTablesContainer>
  );
};
const StyledDivider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray900};
`;
const TournamentTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentTablesHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesRow = styled(StyledRow)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabTables;
