import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PURGE } from 'redux-persist';
import { TournamentData } from '../api/tournamentTypes';
import { tournamentApi } from '../api/tournament';
import { createSelector } from 'reselect';
import { TableEventPacket } from './streamingTypes';
import { TournamentLobbyPacket, TournamentSnapshot } from './tournamentTypes';
import { groupLog, logWarn } from '../../utils/ConsoleUtil';
import { mergeSelectivePropsWithOverride } from './streaming';

type TournamentState = {
  tournamentHasMore: boolean;
  tournamentNextOffset: number | undefined;
  tournamentsData: TournamentData[];
  tournamentLobbySnapshot: Record<string, TournamentLobbyPacket>;
  tournamentTableSnapshot: Record<string, TableEventPacket>;
  tableCodeIdMapper: Record<string, string>;
};

const initialState: TournamentState = {
  tournamentHasMore: true,
  tournamentNextOffset: undefined,
  tournamentsData: [],
  tournamentLobbySnapshot: {},
  tournamentTableSnapshot: {},
  tableCodeIdMapper: {}
};

const tournamentSlice = createSlice({
  name: 'tournamentSlice',
  initialState,
  reducers: {
    storeTournamentLobbySnapshot: (state, payloadAction: PayloadAction<TournamentLobbyPacket | undefined>) => {
      if (!payloadAction.payload) {
        return;
      }
      groupLog('Tournament Packet : ', payloadAction.payload);
      const { header, payload } = payloadAction.payload;

      if (!header) {
        return;
      }

      const { tournamentId } = header;

      if (!tournamentId) {
        return;
      }

      const currentSnapshot = state.tournamentLobbySnapshot[tournamentId]?.payload.snapshot;

      if (payload.snapshot) {
        state.tournamentLobbySnapshot[tournamentId] = payloadAction.payload;
      }
      if (payload.updates && currentSnapshot) {
        const mergeUpdates = (current: TournamentSnapshot, updates: Partial<TournamentSnapshot>): TournamentSnapshot => {
          return {
            ...current,
            ...updates,
            information: {
              ...current.information,
              ...updates.information,
              static: {
                ...current.information.static,
                ...updates.information?.static
              },
              dynamic: {
                ...current.information.dynamic,
                ...updates.information?.dynamic
              }
            },
            structure: {
              ...current.structure,
              ...updates.structure,
              blindSchedule: updates.structure?.blindSchedule || current.structure.blindSchedule,
              payoutStructure: {
                ...current.structure.payoutStructure,
                info: updates.structure?.payoutStructure?.info || current.structure.payoutStructure.info
              }
            },
            prizePool: {
              ...current.prizePool,
              asset: updates.prizePool?.asset || current.prizePool.asset,
              pool: updates.prizePool?.pool || current.prizePool.pool
            },
            inTheMoney: updates.inTheMoney || current.inTheMoney,
            players: updates.players || current.players,
            summary: updates.summary || current.summary,
            tables: updates.tables || current.tables
          };
        };
        state.tournamentLobbySnapshot[tournamentId] = {
          ...state.tournamentLobbySnapshot[tournamentId],
          payload: {
            ...state.tournamentLobbySnapshot[tournamentId].payload,
            snapshot: mergeUpdates(currentSnapshot, payload.updates)
          }
        };
      }
    },
    storeTournamentTableSnapshot: (state, payloadAction: PayloadAction<TableEventPacket>) => {
      if (!payloadAction.payload) {
        return;
      }
      groupLog('Tour.Table Packet : ', payloadAction.payload);
      const { header } = payloadAction.payload;
      logWarn('Tour.Table Header : ', header.action);
      if (!header) {
        return;
      }
      if (payloadAction.payload?.payload?.snapshot?.information?.static?.shareCode) {
        state.tableCodeIdMapper[payloadAction.payload?.payload?.snapshot.information.static.shareCode] = payloadAction.payload?.header?.tableId.toString();
      }
      const previousPacket = state.tournamentTableSnapshot[payloadAction.payload?.header?.tableId] || {};
      const tableSnapshot = mergeSelectivePropsWithOverride(previousPacket?.payload?.snapshot, payloadAction.payload.payload.snapshot);
      const tableSnapshotWithUpdate = mergeSelectivePropsWithOverride(tableSnapshot, payloadAction.payload.payload.updates);

      state.tournamentTableSnapshot[payloadAction.payload?.header?.tableId] = payloadAction.payload;
      state.tournamentTableSnapshot[payloadAction.payload?.header?.tableId] = {
        name: payloadAction.payload.name,
        header: payloadAction.payload.header,
        payload: {
          snapshot: {
            ...tableSnapshotWithUpdate
          }
        }
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
    builder.addMatcher(tournamentApi.endpoints.tournamentList.matchFulfilled, (state, { payload }) => {
      const response = payload?.data;
      const tournaments = response.tournaments || [];
      const tournamentsMap = new Map(state.tournamentsData.map(item => [item.id, item]));
      tournaments.forEach(tournament => {
        tournamentsMap.set(tournament.id, tournament);
      });
      // ID 내림차순으로 정렬
      state.tournamentsData = Array.from(tournamentsMap.values());
      state.tournamentHasMore = response.nextOffset !== -1;
      state.tournamentNextOffset = response.nextOffset;
    });

    builder.addMatcher(tournamentApi.endpoints.tournamentList.matchRejected, (state, { payload }) => {
      state.tournamentHasMore = false;
    });
  }
});

export default tournamentSlice;

export const selectTournaments = (state: RootState) => state.tournamentSlice.tournamentsData;
export const selectTournamentById = createSelector([selectTournaments, (_: RootState, id: number | undefined) => id], (tournaments, id) => {
  return tournaments.find(tournament => tournament.id === id) ?? undefined;
});
export const selectTournamentOffset = (state: RootState) => state.tournamentSlice.tournamentNextOffset;

const getTournamentLobbySnapshots = (state: RootState) => state.tournamentSlice.tournamentLobbySnapshot;
export const selectTournamentLobbyPacket = createSelector([getTournamentLobbySnapshots, (_: RootState, tournamentId: string | undefined | null) => tournamentId], (tournamentLobbySnapshot, tournamentId) => {
  return tournamentId !== undefined && tournamentId !== null ? tournamentLobbySnapshot[tournamentId] : null;
});

export const selectTournamentTableIdByShareCode = (state: RootState, shareCode: string) => {
  return state.tournamentSlice.tableCodeIdMapper[shareCode];
};

const getTournamentTableSnapshot = (state: RootState) => state.tournamentSlice.tournamentTableSnapshot;

export const selectTournamentTablePacket = createSelector([getTournamentTableSnapshot, (state: RootState, tournamentTableShareCode: string) => selectTournamentTableIdByShareCode(state, tournamentTableShareCode)], (tableSnapshots, tournamentTableId) => {
  return tableSnapshots[tournamentTableId] || [];
});
