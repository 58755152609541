import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import i18n, { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../i18n';
import { logDebug, logError } from '../utils/ConsoleUtil';
import useLangNavigate from './useLangNavigate';

const useLanguage = () => {
  const { lang } = useParams<{ lang: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const langNavigate = useLangNavigate();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [isResourcesLoaded, setIsResourcesLoaded] = useState(false);

  const isSupportedLanguage = (language: string): boolean => SUPPORTED_LANGUAGES.includes(language);

  const shouldSkipLanguageUpdate = (): boolean => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    return pathSegments.length > 0 && pathSegments[0] === 'auth'; // Skip for paths starting with 'auth'
  };

  const updatePathWithLanguage = (language: string): void => {
    if (shouldSkipLanguageUpdate()) {
      logDebug('Skipping language update for path:', location.pathname);
      return;
    }

    const pathSegments = location.pathname.split('/').filter(Boolean);

    if (isSupportedLanguage(pathSegments[0])) {
      pathSegments.shift(); // Remove current language from path
    }
    if (language !== DEFAULT_LANGUAGE) {
      pathSegments.unshift(language); // Add new language to the path
    }
    const newPath = `/${pathSegments.join('/')}${location.search}${location.hash}`;
    if (newPath !== location.pathname) {
      navigate(newPath);
    }
  };

  const changeLanguage = (newLang: string): void => {
    if (newLang === i18n.language) return;

    const targetLanguage = isSupportedLanguage(newLang) ? newLang : DEFAULT_LANGUAGE;
    i18n
      .changeLanguage(targetLanguage)
      .then(() => {
        i18n
          .loadLanguages(targetLanguage, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              setCurrentLang(targetLanguage);
              updatePathWithLanguage(targetLanguage);
            }
          })
          .then();
      })
      .catch(error => {
        logError('Failed to change language:', error);
      });
  };

  useEffect(() => {
    i18n.on('initialized', () => {
      setIsResourcesLoaded(true);
    });

    return () => {
      i18n.off('initialized');
    };
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (newLanguage: string) => {
      if (newLanguage !== currentLang) {
        setCurrentLang(newLanguage);
        updatePathWithLanguage(newLanguage);
      }
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [currentLang, location.pathname]);

  useEffect(() => {
    if (isResourcesLoaded) {
      if (!lang) {
        // lang 파라미터가 없을 때 처리
        // 쿠키가 있는지 확인
        const cookieExists = typeof document !== 'undefined' && document.cookie.includes('i18next=');

        if (!cookieExists) {
          // 쿠키가 없으므로 시스템 언어 감지
          const systemLangFull = navigator.language || (navigator.languages && navigator.languages[0]);
          const systemLang = systemLangFull ? systemLangFull.split('-')[0] : DEFAULT_LANGUAGE;

          if (systemLang && isSupportedLanguage(systemLang)) {
            i18n
              .changeLanguage(systemLang, err => {
                if (err) {
                  logError('Failed to load system language resources:', err);
                } else {
                  i18n
                    .loadLanguages(systemLang, err => {
                      if (err) {
                        logError('Failed to load language resources:', err);
                      } else {
                        setCurrentLang(systemLang);
                        updatePathWithLanguage(systemLang);
                      }
                    })
                    .then();
                }
              })
              .catch(error => {
                logError('Failed to change language to system language:', error);
              });
          } else {
            // 시스템 언어가 지원되지 않는 경우 기본 언어로 설정
            if (currentLang !== DEFAULT_LANGUAGE && !shouldSkipLanguageUpdate()) {
              i18n
                .changeLanguage(DEFAULT_LANGUAGE, err => {
                  if (err) {
                    logError('Failed to load default language resources:', err);
                  } else {
                    i18n
                      .loadLanguages(DEFAULT_LANGUAGE, err => {
                        if (err) {
                          logError('Failed to load language resources:', err);
                        } else {
                          setCurrentLang(DEFAULT_LANGUAGE);
                          updatePathWithLanguage(DEFAULT_LANGUAGE);
                        }
                      })
                      .then();
                  }
                })
                .catch(error => {
                  logError('Failed to change language to default:', error);
                });
            }
          }
        } else {
          // 쿠키가 있는 경우에는 쿠키 값에 따라 path를 업데이트
          if (currentLang !== DEFAULT_LANGUAGE && !shouldSkipLanguageUpdate()) {
            updatePathWithLanguage(currentLang);
          }
        }
        return;
      }

      // lang 파라미터가 있는 경우 (기존 로직)
      if (!isSupportedLanguage(lang)) {
        const decodedPath = decodeURIComponent(location.pathname);
        const path = decodedPath.replace(`/${lang}`, '');
        navigate(path || '/', { replace: true });
        return;
      }

      if (lang !== currentLang) {
        i18n
          .changeLanguage(lang, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              i18n
                .loadLanguages(lang, err => {
                  if (err) {
                    logError('Failed to load language resources:', err);
                  } else {
                    setCurrentLang(lang);
                    updatePathWithLanguage(lang);
                  }
                })
                .then();
            }
          })
          .then(() => {
            setCurrentLang(lang);
          })
          .catch(error => {
            logError('Failed to change language:', error);
          });
      } else {
        i18n
          .loadLanguages(lang, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              setCurrentLang(lang);
              updatePathWithLanguage(lang);
            }
          })
          .then();
      }
    }
  }, [lang, location.pathname, navigate, langNavigate, currentLang, isResourcesLoaded]);

  return { lang, changeLanguage, isResourcesLoaded };
};

export default useLanguage;
