import styled from '@emotion/styled';
import Button from 'src/components/common/button';
import Input from 'src/components/common/input';
import BaseButton from '../../../common/button/BaseButton';

export const StyledInput = styled(Input)`
  width: 80px;
  height: 100%;
  border-radius: 6px;

  border: none;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 2560px) {
    width: 64px;
  }
  @media (max-width: 1600px) {
    width: 54px;
  }
  @media (max-width: 1280px) {
    width: 50px;
    border-radius: 4px;
  }

  .input-inner,
  .input-inner::placeholder {
    ${({ theme }) => theme.textStyles.TextLg_Medium};
    @media (max-width: 2560px) {
      ${({ theme }) => theme.textStyles.TextSmall_Medium};
    }
    @media (max-width: 1600px) {
      ${({ theme }) => theme.textStyles.TextXs_Medium};
    }
    @media (max-width: 1280px) {
      ${({ theme }) => theme.textStyles.Text2xs_Medium};
    }
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.error600};
    outline: none;
  }
`;
export const SliderGroup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    height: 240px;
  }
`;

export const PresetWrap = styled.div`
  margin-bottom: 8px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 45%;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.3);
  }
  bottom: 100%;
  z-index: 200;
  background-color: ${({ theme }) => theme.colors.gray600_30};
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 8px;
  height: 72px;
  @media (max-width: 2560px) {
    height: 60px;
  }
  @media (max-width: 1600px) {
    padding: 8px 12px;
    height: 48px;
  }
  @media (max-width: 1280px) {
    width: 360px;
    padding: 4px;
    height: 32px;
    background-color: rgba(16, 24, 40, 0.29);
  }
  @media (max-width: 768px) {
    width: 136px;
    height: auto;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.gray900};
  }
`;

export const PresetButtons = styled.div`
  gap: 8px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    & > button {
      height: 54px;
    }
  }
`;

export const CardWrap = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;
  box-sizing: border-box;
`;

export const ShowCardWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 16px;
  @media (max-width: 1280px) {
    padding: 3px 3px 3px 8px;
  }
  @media (max-width: 768px) {
    padding: 3px 3px 3px 8px;
  }
`;
export const StyledShowCardText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Bold};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  box-sizing: border-box;
`;
export const AuctionButtonsContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  width: 808px;

  @media (max-width: 2560px) {
    width: 616px;
  }
  @media (max-width: 1600px) {
    width: 542px;
    padding: 16px;
  }
  @media (max-width: 1440px) {
    width: 496px;
  }
  @media (max-width: 1280px) {
    width: 406px;
    padding: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    min-height: 64px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

export const StyledCheckButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledCheckButton = styled(BaseButton)<{ isChecked?: boolean; type: 'checkbox' | 'bar' }>`
  padding-left: 24px;
  @media (max-width: 1440px) {
    height: 50px;
    padding-left: 12px;
  }
  @media (max-width: 1280px) {
    height: 34px;
    padding-left: 8px;
    width: 130px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    padding-left: 7px;
    width: 100%;
    height: 48px;
    border-radius: 6px;
  }

  .checkButton {
    cursor: pointer;
    color: ${({ theme, isChecked }) => theme.colors[isChecked ? 'white' : 'gray400']};
    line-height: 24px;
    @media (max-width: 1440px) {
      ${({ theme }) => theme.textStyles.TextMd_Bold};
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      ${({ theme }) => theme.textStyles.TextXs_Bold}
      line-height: 13px;
    }
    @media (max-width: 768px) {
      ${({ theme }) => theme.textStyles.TextSmall_Bold};
      line-height: 16px;
    }
  }

  &:hover {
    .checkButton {
      color: ${({ theme, isChecked }) => !isChecked && theme.colors.gray300};
    }
  }
`;

export const StyledActionButton = styled(BaseButton)`
  line-height: 24px;
  border-radius: 10px;
  ${({ theme }) => theme.textStyles.DisplayXs_Bold};
  @media (max-width: 2560px) {
    border-radius: 8px;
    ${({ theme }) => theme.textStyles.TextXl_Bold};
    line-height: 22px;
    width: 200px;
    height: 70px;
  }
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.TextMd_Bold};
    line-height: 18px;
    border-radius: 6px;
    width: 175px;
    height: 54px;
  }
  @media (max-width: 1440px) {
    width: 160px;
    height: 50px;
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXm_Bold};
    width: 130px;
    height: 34px;
    line-height: 13px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    width: 100%;
    ${({ theme }) => theme.textStyles.TextMd_Bold};
    height: 48px;
    line-height: 18px;
    border-radius: 6px;
  }
`;

export const StyledSeatButton = styled(StyledActionButton)`
  @media (max-width: 2560px) {
    width: 240px;
  }
  @media (max-width: 1600px) {
    width: 190px;
  }
  @media (max-width: 1440px) {
    width: 180px;
  }
  @media (max-width: 1280px) {
    width: 160px;
  }
`;

export const StyledShowCardButton = styled(BaseButton)`
  width: 100%;
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXm_Bold};
    height: 44px;
    border-radius: 6px;
  }
  @media (max-width: 768px) {
    height: 48px;
  }
`;

export const StyledPresetButton = styled(BaseButton)`
  line-height: 24px;
  width: 96px;
  height: 100%;
  ${({ theme }) => theme.textStyles.TextLg_Bold};
  @media (max-width: 2560px) {
    ${({ theme }) => theme.textStyles.TextSmall_Bold};
    width: 64px;
  }
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.TextXs_Bold};
    width: 54px;
  }
  @media (max-width: 1440px) {
    width: 48px;
  }
  @media (max-width: 1280px) {
    border-radius: 4px;
    ${({ theme }) => theme.textStyles.Text3xs_Bold};
    width: 36px;
    line-height: 13px;
  }
  @media (max-width: 768px) {
    line-height: 10px;
    border-radius: 8px;
    ${({ theme }) => theme.textStyles.TextMd_Bold};
    width: 64px;
  }
`;
