import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import BaseButton from '../../common/button/BaseButton';
import Text from '../../common/text/Text';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import LinearLayout from '../../layout/LinearLayout';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { timeFormat, toFixedFloor } from '../../../utils/StringUtil';
import { getTournamentDate, getTournamentDuration } from '../../../utils/DateUtil';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useOpenTable from '../../../hooks/useOpenTable';
import { TournamentStatus } from '../../../store/slices/tournamentTypes';
import useDevice from '../../../hooks/useDevice';
import TournamentTimer from './TournamentTimer';
import useCountdown from '../../../hooks/useCountdown';
import { useLocation, useNavigate } from 'react-router-dom';

const TournamentStateTimer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useDevice();
  const { state } = location;
  const { tournamentId, tournamentInfo, isRegistered, tournamentStructure, totalPrizeAmount, tournamentStatus, isRegisterEnable, tournamentAsset, myTournamentTableShareCode, currentBlindInfo, hasNextBlind, hasNextBreak, currentBlindEndAt, nextBreakStartedAt, isTableAccessible } =
    useTournamentLobby();
  const { openTable } = useOpenTable();
  const [userAction, setUserAction] = useState<string | undefined>();
  const blindLeft = useCountdown(currentBlindEndAt);
  const breakLeft = useCountdown(nextBreakStartedAt);
  const navigate = useNavigate();

  const register = () => {
    if (tournamentId !== null) {
      dispatch(showModalWithRequest({ type: ModalType.TournamentRegisterModal, data: { tournamentId: tournamentId } }));
    }
  };

  const unregister = () => {
    if (tournamentId !== null) {
      dispatch(showModalWithRequest({ type: ModalType.TournamentUnregisterModal, data: { tournamentId: tournamentId } }));
    }
  };

  const reEntry = () => {};

  useEffect(() => {
    setUserAction(state?.type);
    navigate('.', { replace: true, state: null });
  }, [state?.type]);

  useEffect(() => {
    if (userAction) {
      switch (userAction) {
        case 'REGISTER':
          register();
          break;
        case 'UNREGISTER':
          unregister();
          break;
        case 'OPENTABLE':
          openTable(myTournamentTableShareCode, tournamentId);
          break;
      }
      setUserAction(undefined);
    }
  }, [userAction]);

  const getStatus = () => {
    switch (tournamentStatus) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
        return 'Registering';
      case TournamentStatus.PREPARING:
        return 'Preparing';
      case TournamentStatus.LATE_REGISTRATION:
        return 'Late Registration';
      case TournamentStatus.RUNNING:
        return 'Running';
      case TournamentStatus.CLOSED:
        return 'Finished';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (): keyof typeof Colors => {
    switch (tournamentStatus) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
      case TournamentStatus.PREPARING:
        return 'primary400';
      case TournamentStatus.LATE_REGISTRATION:
        return 'error500';
      case TournamentStatus.RUNNING:
        return 'gray400';
      case TournamentStatus.CLOSED:
        return 'gray400';
      default:
        return 'primary200';
    }
  };

  const getStatusView = () => {
    switch (tournamentInfo?.dynamic?.status) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
      case TournamentStatus.PREPARING:
        return (
          <>
            <TitleValueHorizontal label={`Starting Time`} value={getTournamentDate(tournamentInfo?.static.startingTime)} labelColor="gray400" valueColor="gray300" />
            <TitleValueHorizontal label={`Estimated Duration`} value={getTournamentDuration(tournamentInfo?.static.estimatedDuration)} labelColor="gray400" valueColor="gray300" />
          </>
        );
      case TournamentStatus.LATE_REGISTRATION:
      case TournamentStatus.RUNNING:
        return (
          <>
            {currentBlindInfo?.type === 'BLIND' && (
              <>
                <TitleValueHorizontal label={`Blind Level ${currentBlindInfo?.level}`} value={`${currentBlindInfo?.sb}/${currentBlindInfo?.bb}`} labelColor="gray400" valueColor="gray300" />
                {hasNextBlind && <TitleValueHorizontal label="Next Blind" value={timeFormat(blindLeft)} labelColor="gray400" valueColor="gray300" />}
                {hasNextBreak && <TitleValueHorizontal label="Next Break" value={timeFormat(breakLeft)} labelColor="gray400" valueColor="gray300" />}
              </>
            )}
            {currentBlindInfo?.type === 'BREAK' && (
              <>
                <TitleValueHorizontal label={`Break Time`} value={timeFormat(blindLeft)} labelColor="gray400" valueColor="gray300" />
              </>
            )}
          </>
        );
      case TournamentStatus.CLOSED:
      default:
        return (
          <>
            <TitleValueHorizontal label={`Level ${currentBlindInfo?.level}`} value={`${currentBlindInfo?.sb}/${currentBlindInfo?.bb}`} labelColor="gray400" valueColor="gray300" />
          </>
        );
    }
  };

  const getPrizeSection = () => (
    <PrizePoolSection>
      <LinearLayout gap={4}>
        <Text fontStyle="TextMd_Semibold" textColor="white" text="Prize Pool" textAlign={'start'} />
        <HorizontalLayout margin={'0'} justifyContent={'start'} gap={4}>
          <AssetImage src={tournamentAsset?.symbolImage} alt={tournamentAsset?.name} />
          <Text fontStyle="TextXl_Semibold" textColor="primary400" text={toFixedFloor(totalPrizeAmount, 0, false, true)} textAlign={'start'} />
        </HorizontalLayout>
      </LinearLayout>
      <div>
        {isRegisterEnable && !myTournamentTableShareCode && (
          <BaseButton size="btn40" w={105} textStyle={'TextSmall_Semibold'} color={!isRegisterEnable ? 'gray' : isRegistered ? 'red' : 'primary'} disabled={!isRegisterEnable} onClick={isRegistered ? unregister : register}>
            {isRegistered ? 'Unregister' : 'Register'}
          </BaseButton>
        )}
        {!isRegisterEnable && isTableAccessible && (
          <BaseButton
            size="btn40"
            w={105}
            textStyle={'TextSmall_Semibold'}
            color={isRegistered ? 'red' : 'primary'}
            disabled={!isRegistered}
            onClick={() => {
              openTable(myTournamentTableShareCode, tournamentId);
            }}
          >
            {'Take a seat'}
          </BaseButton>
        )}
        {tournamentStatus === TournamentStatus.CLOSED && (
          <BaseButton textStyle={'TextSmall_Semibold'} size="btn40" w={105} color={'gray'} disabled={true} onClick={() => {}}>
            {'Finished'}
          </BaseButton>
        )}
      </div>
    </PrizePoolSection>
  );

  return (
    <>
      {isMobile && getPrizeSection()}
      <TournamentDetailBox>
        <Wrap>
          {!isMobile && getPrizeSection()}
          <InfoSection>
            <TournamentTimer endAt={tournamentInfo?.static.startingTime} />
            <LinearLayout gap={4} flex={1}>
              <Text fontStyle="TextMd_Semibold" textColor={getStatusColor()} text={getStatus()} textAlign={'start'} marginBottom={12} />
              {getStatusView()}
            </LinearLayout>
          </InfoSection>
        </Wrap>
      </TournamentDetailBox>
    </>
  );
};

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    gap: 12px;
    padding: 16px;
    box-sizing: border-box;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 24px;

  color: white;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const PrizePoolSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 22px;
  height: 100%;
  min-width: 105px;
  margin-right: 32px;
  justify-content: space-between;
  @media (max-width: 1040px) {
    margin-right: 24px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    padding: 24px 16px;
    box-sizing: border-box;
    margin-bottom: 0;
  }
`;

const AssetImage = styled.img`
  width: 20px;
  height: 20px;
  align-self: center;
`;

export default TournamentStateTimer;
