import React from 'react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { PrizeAmount } from '../../../store/slices/tournamentTypes';
import { toFixedFloor } from '../../../utils/StringUtil';
import useDevice from '../../../hooks/useDevice';

const TournamentDetailTabPrizePool: React.FC = () => {
  const { tournamentPrizePool, tournamentAsset } = useTournamentLobby();
  const { isMobile } = useDevice();

  const renderPrizePool = (payoutInfo: PrizeAmount) => (
    <StyledPrizePoolRow key={payoutInfo.id} selected={false}>
      <StyledPrizePoolCell color={'white'}>{payoutInfo.maxRank === payoutInfo.minRank ? `${payoutInfo.minRank}` : `${payoutInfo.minRank}~${payoutInfo.maxRank}`}</StyledPrizePoolCell>
      <StyledPrizePoolCell color={'white'}>{toFixedFloor(Number(payoutInfo.prizeAmount), tournamentAsset?.displayDecimals ?? 0, false, true) + ' ' + tournamentAsset?.name}</StyledPrizePoolCell>
    </StyledPrizePoolRow>
  );

  return (
    <PrizePoolContainer>
      <TableSection flex={1} maxHeight={isMobile ? 541 : 592}>
        <TitleText fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} marginBottom={isMobile ? 16 : 12} marginTop={16} textColor={'white'} text={'Prize Pool'} textAlign={'start'} />
        <StyledTableHeader>
          <StyledPrizePoolHeader>
            <StyledPrizePoolCell color={'gray400'}>Rank</StyledPrizePoolCell>
            <StyledPrizePoolCell color={'gray400'}>Award</StyledPrizePoolCell>
          </StyledPrizePoolHeader>
        </StyledTableHeader>
        <StyledTableBody>
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}

          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
          {tournamentPrizePool?.pool?.map(renderPrizePool)}
        </StyledTableBody>
      </TableSection>
    </PrizePoolContainer>
  );
};

const TitleText = styled(Text)`
  margin-left: 24px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;

const PrizePoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledPrizePoolHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolCell = styled(StyledCell)``;

export default TournamentDetailTabPrizePool;
