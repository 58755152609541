import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import routers from './routers';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/store';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { AuthProvider } from './hooks/AuthContext';
import 'react-loading-skeleton/dist/skeleton.css';
import '@splidejs/react-splide/css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

export const isDebug = process.env.NODE_ENV === 'development';
ReactGA.initialize(process.env.REACT_APP_GA ?? 'G-H4Q2MZFX6M');
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/develop\.testbase\.xyz/, /^https:\/\/www\.basepoker\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement as HTMLElement);
Modal.setAppElement(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={routers} />
          </Suspense>
        </AuthProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
