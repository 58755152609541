import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { TournamentPlayer } from '../../../store/slices/tournamentTypes';
import { toFixedFloor } from '../../../utils/StringUtil';
import Text from '../../common/text/Text';
import useOpenTable from '../../../hooks/useOpenTable';
import TournamentTablePreview from './TournamentTablePreview';
import useDevice from '../../../hooks/useDevice';
import { ReactComponent as EmptyIcon } from 'src/assets/img/user-empty.svg';

const TournamentDetailTabPlayers: React.FC = () => {
  const { tournamentId, tournamentPlayers, tournamentData, tournamentStatus } = useTournamentLobby();
  const [selectedUserShareCode, setSelectedUserShareCode] = useState<string>();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const { openTable } = useOpenTable();
  const { isMobile } = useDevice();

  const selectUser = (userId: number, shareCode?: string) => {
    setSelectedUserShareCode(shareCode);
    setSelectedUserId(userId);
  };

  useEffect(() => {
    if (tournamentPlayers) {
      setSelectedUserShareCode(tournamentPlayers[0]?.shareCode);
      setSelectedUserId(tournamentPlayers?.[0]?.userId);
    }
  }, []);

  const renderRow = (index: number, tournamentPlayer: TournamentPlayer) => {
    return (
      <TournamentPlayersRow key={`${tournamentPlayer.userId}-${index}`} selected={selectedUserId === tournamentPlayer.userId} onClick={() => selectUser(tournamentPlayer.userId, tournamentPlayer?.shareCode)} onDoubleClick={() => openTable(tournamentPlayer?.shareCode, tournamentId)}>
        <TournamentPlayersCell color={'white'}>{tournamentPlayer.rank === 0 ? '-' : tournamentPlayer.rank}</TournamentPlayersCell>
        <TournamentPlayersCell color={'white'}>{<StyledFlag src={`https://assets.testbase.xyz/national_flag/${tournamentPlayer.flag}.png`} alt={`user-flag`} />}</TournamentPlayersCell>
        <TournamentPlayersCell color={'white'}>{tournamentPlayer.nickname}</TournamentPlayersCell>
        <TournamentPlayersCell color={'white'}>{toFixedFloor(tournamentPlayer.chips, 0, false, true)}</TournamentPlayersCell>
      </TournamentPlayersRow>
    );
  };

  return (
    <TournamentPlayersContainer>
      <TableSection flex={1} maxHeight={isMobile || selectedUserShareCode ? 256 : undefined}>
        <StyledTableHeader>
          <TournamentPlayersHeader>
            <TournamentPlayersCell color={'gray400'}>Rank</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Flag</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Player</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Chips(BB)</TournamentPlayersCell>
          </TournamentPlayersHeader>
        </StyledTableHeader>
        <StyledTableBody>
          {tournamentPlayers && tournamentPlayers?.length > 0 ? (
            tournamentPlayers?.map((value, index) => renderRow(index, value))
          ) : (
            <StyledUserEmpty>
              <EmptyIcon />
              <NoPlayersMessage fontStyle={'TextSmall_Medium'} textColor={'gray300'} text={'No Players'} />
            </StyledUserEmpty>
          )}
        </StyledTableBody>
      </TableSection>

      {tournamentId && selectedUserShareCode && (
        <>
          <StyledDivider />
          <TableSection flex={1.2}>
            <TournamentTablePreview tournamentId={tournamentId} shareCode={selectedUserShareCode} />
          </TableSection>
        </>
      )}
    </TournamentPlayersContainer>
  );
};
const StyledUserEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: 268px;
  @media (max-width: 768px) {
    min-height: auto;
  }
`;

const StyledFlag = styled.img`
  width: 20px;
  height: 15px;
  border-radius: 2px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray900};
`;

const TournamentPlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentPlayersHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
`;

const TournamentPlayersRow = styled(StyledRow)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
  user-select: none;
`;

const TournamentPlayersCell = styled(StyledCell)`
  align-items: center;
  padding: 8px;
`;

const NoPlayersMessage = styled(Text)`
  align-self: center;
`;

export default TournamentDetailTabPlayers;
