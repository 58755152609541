import React from 'react';
import CheckButton from './CheckButton';

import { PreActionsProps } from './types';
import styled from '@emotion/styled';

const PreActions = ({ isMobile, getActions, getActionText, handleSelect, selectedAction }: PreActionsProps) => {
  const formatActionText = (action: string): string => {
    if (isMobile && action.includes('/')) {
      return action.replace('/', '/\\n'); // '/' 뒤에 줄바꿈 추가
    }
    return action;
  };
  return (
    <StyledWrap>
      {getActions().map((action, i) => (
        <StyledCheckButton
          key={i}
          isMobile={isMobile}
          size={isMobile ? 'btn48' : 'btn60'}
          type={isMobile ? 'bar' : 'checkbox'}
          isChecked={selectedAction === action}
          color="gray"
          textColor="white"
          textStyle="TextMd_Bold"
          text={formatActionText(getActionText(action))}
          onClick={() => handleSelect(action)}
        />
      ))}
    </StyledWrap>
  );
};
const StyledWrap = styled.div`
  display: flex;
  gap: 8px;
  min-width: 616px;
  @media (max-width: 1440px) {
    min-width: 496px;
  }
  @media (max-width: 1280px) {
    min-width: 402px;
  }
  @media (max-width: 768px) {
    width: 100%;
    min-width: 0;
  }
`;

const StyledCheckButton = styled(CheckButton)``;

export default PreActions;
