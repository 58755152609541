import { css } from '@emotion/react';
import { SupportedLangType } from '../i18n';

export const fontFamilies: { [key in SupportedLangType]: any } = {
  ja: {
    regular: 'Noto Sans JP, Yellix-Regular',
    medium: 'Noto Sans JP, Yellix-Medium',
    semiBold: 'Noto Sans JP, Yellix-SemiBold',
    bold: 'Noto Sans JP, Yellix-Bold'
  },
  en: {
    regular: 'Yellix-Regular',
    medium: 'Yellix-Medium',
    semiBold: 'Yellix-SemiBold',
    bold: 'Yellix-Bold'
  },
  es: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  },
  fr: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  },
  id: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  },
  pt: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  },
  ru: {
    regular: 'Noto Sans, Yellix-Regular',
    medium: 'Noto Sans, Yellix-Medium',
    semiBold: 'Noto Sans, Yellix-SemiBold',
    bold: 'Noto Sans, Yellix-Bold'
  },
  zh: {
    regular: 'Noto Sans TC, Yellix-Regular',
    medium: 'Noto Sans TC, Yellix-Medium',
    semiBold: 'Noto Sans TC, Yellix-SemiBold',
    bold: 'Noto Sans TC, Yellix-Bold'
  },
  'zh-tw': {
    regular: 'Noto Sans TC, Yellix-Regular',
    medium: 'Noto Sans TC, Yellix-Medium',
    semiBold: 'Noto Sans TC, Yellix-SemiBold',
    bold: 'Noto Sans TC, Yellix-Bold'
  },
  de: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  },
  vi: {
    regular: 'Noto Sans, Yellix-Regular',
    medium: 'Noto Sans, Yellix-Medium',
    semiBold: 'Noto Sans, Yellix-SemiBold',
    bold: 'Noto Sans, Yellix-Bold'
  },
  nl: {
    regular: 'Yellix-Regular, Lunasima-Bold',
    medium: 'Yellix-Medium, Lunasima-Bold',
    semiBold: 'Yellix-SemiBold, Lunasima-Bold',
    bold: 'Yellix-Bold, Lunasima-Bold'
  }
};

const getFontWeight = (lang: SupportedLangType, weight: number) => (!['en', 'pt', 'id', 'fr', 'es', 'de', 'nl'].includes(lang) ? `font-weight: ${weight};` : '');

export const createTextStyle = (lang: SupportedLangType) => ({
  Text6xl: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  Text6xl_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  Text6xl_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  Text6xl_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  Display2xl: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 72px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 90px; /* 125% */
    letter-spacing: -1.44px;
  `,
  Display2xl_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 72px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 90px; /* 125% */
    letter-spacing: -1.44px;
  `,
  Display2xl_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 72px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 90px; /* 125% */
    letter-spacing: -1.44px;
  `,
  Display2xl_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 72px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 90px; /* 125% */
    letter-spacing: -1.44px;
  `,
  DisplayXl: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  DisplayXl_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  DisplayXl_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  DisplayXl_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 60px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
  `,
  DisplayLg: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 48px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 60px; /* 125% */
    letter-spacing: -0.96px;
  `,
  DisplayLg_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 48px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 60px; /* 125% */
    letter-spacing: -0.96px;
  `,
  DisplayLg_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 48px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 60px; /* 125% */
    letter-spacing: -0.96px;
  `,
  DisplayLg_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 48px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 60px; /* 125% */
    letter-spacing: -0.96px;
  `,
  DisplayMd: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 36px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  `,
  DisplayMd_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 36px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  `,
  DisplayMd_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 36px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  `,
  DisplayMd_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 36px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  `,
  DisplaySm: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 30px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 30px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 30px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 30px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 38px; /* 126.667% */
  `,
  DisplayXs: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 24px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 24px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 24px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 24px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 32px; /* 133.333% */
  `,
  TextXl: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 20px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 30px; /* 150% */
  `,
  TextXl_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 20px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 30px; /* 150% */
  `,
  TextXl_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 20px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 30px; /* 150% */
  `,
  TextXl_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 20px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 30px; /* 150% */
  `,
  TextLg: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 18px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 28px; /* 155.556% */
  `,
  TextLg_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 18px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 28px; /* 155.556% */
  `,
  TextLg_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 18px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 28px; /* 155.556% */
  `,
  TextLg_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 18px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 28px; /* 155.556% */
  `,
  TextMd: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 16px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 24px; /* 150% */
  `,
  TextMd_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 16px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 24px; /* 150% */
  `,
  TextMd_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 16px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 24px; /* 150% */
  `,
  TextMd_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 16px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 24px; /* 150% */
  `,
  TextSmall: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 14px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 20px; /* 142.857% */
  `,
  TextSmall_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 14px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 20px; /* 142.857% */
  `,
  TextSmall_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 14px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 20px; /* 142.857% */
  `,
  TextSmall_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 14px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 20px; /* 142.857% */
  `,
  TextXm: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 126.667% */
  `,
  TextXm_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 126.667% */
  `,
  TextXm_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 126.667% */
  `,
  TextXm_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 126.667% */
  `,
  TextXs: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 12px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 16px; /* 150% */
  `,
  TextXs_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 12px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 16px; /* 150% */
  `,
  TextXs_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 12px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 16px; /* 150% */
  `,
  TextXs_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 12px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 16px; /* 150% */
  `,
  Text2xs: css`
    font-family: ${fontFamilies[lang].regular};
    font-size: 11px;
    font-style: normal;
    ${getFontWeight(lang, 400)}
    line-height: 16px; /* 150% */
  `,
  Text2xs_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 11px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 16px; /* 150% */
  `,
  Text2xs_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 11px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 16px; /* 150% */
  `,
  Text2xs_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 11px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 16px; /* 150% */
  `,
  Text3xs_Medium: css`
    font-family: ${fontFamilies[lang].medium};
    font-size: 10px;
    font-style: normal;
    ${getFontWeight(lang, 500)}
    line-height: 14px; /* 150% */
  `,
  Text3xs_Semibold: css`
    font-family: ${fontFamilies[lang].semiBold};
    font-size: 10px;
    font-style: normal;
    ${getFontWeight(lang, 600)}
    line-height: 14px; /* 150% */
  `,
  Text3xs_Bold: css`
    font-family: ${fontFamilies[lang].bold};
    font-size: 10px;
    font-style: normal;
    ${getFontWeight(lang, 700)}
    line-height: 4px; /* 150% */
  `
});
export const TextStyles = {
  ...createTextStyle('en') // 영어를 초기값으로
};
