import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Text from '../../../../common/text/Text';
import { useTableContext } from '../../../../../hooks/TableProvider';
import { useTournamentLobby } from '../../../../../hooks/useTournamentLobby';
import { logWarn } from '../../../../../utils/ConsoleUtil';
import { timeFormat } from '../../../../../utils/StringUtil';
import { HeaderAction } from '../../../../../store/slices/streamingTypes';
import { ModalType, showModal } from '../../../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';

type MessageType = 'seating' | 'startingSoon' | 'waiting' | 'starting' | 'blindsUp' | 'moneyIn' | 'finalWaiting' | 'break' | 'resume' | 'waitingPlayer' | 'cancel' | 'end' | 'paused';

const TableMessage = () => {
  const { tournamentStatus } = useTournamentLobby();
  const { action, blindInfo, tournamentTableInformation } = useTableContext();

  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<MessageType>();
  const [hasTimer, setHasTimer] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>('10:00');

  const [nextBlind, setNextBlind] = useState<string>('');
  const interval = useRef<NodeJS.Timeout | null>(null);
  const messageTimeout = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    logWarn('Message', tournamentStatus, action);
    if (tournamentStatus === 'PREPARING') {
      setMessage('Preparing');
    }
    if (action) {
      setMessage(action);
    }

    // if (tournamentTableInformation?.dynamic.status) {
    //   setMessage(tournamentTableInformation?.dynamic.status);
    // }
    // if (blindInfo) {
    //   setMessage(blindInfo.current.bb.toString());
    // }
  }, [tournamentTableInformation, blindInfo, action, tournamentStatus]);

  useEffect(() => {
    const clearTimers = () => {
      if (interval.current) clearInterval(interval.current);
      if (messageTimeout.current) clearTimeout(messageTimeout.current);
    };

    if (((action === HeaderAction.CONNECT && blindInfo?.current.type === 'BREAK') || (action === HeaderAction.TOURNAMENT_MESSAGE && blindInfo?.current.type === 'BREAK')) && blindInfo.current.endAt) {
      setMessage('Break time');
      setHasTimer(true);
      dispatch(showModal(ModalType.BreakTimeModal));
      const stateEndTime = new Date(blindInfo.current.endAt);
      interval.current = setInterval(() => {
        const now = new Date().getTime();
        const diff = stateEndTime.getTime() - now;
        setTimer(timeFormat(diff));
        if (diff <= 0) {
          clearInterval(interval.current!);
          setTimer(timeFormat(0));
          setMessage(null);
        }
      }, 500);
    } else if (tournamentTableInformation?.dynamic.status === 'WAITING') {
      setMessage('Waiting for other players');
      setHasTimer(false);
      setTimer('');
      messageTimeout.current = setTimeout(() => setMessage(null), 3000);
    } else {
      clearTimers();
      setHasTimer(false);
      setTimer('');
      setMessage(null);
    }

    return () => clearTimers();
  }, [action, blindInfo, tournamentTableInformation]);

  useEffect(() => {
    if (message && !hasTimer) {
      messageTimeout.current = setTimeout(() => setMessage(null), 3000);
    }
    return () => {
      if (messageTimeout.current) clearTimeout(messageTimeout.current);
    };
  }, [message, hasTimer]);

  return (
    <>
      {message && (
        <Wrap>
          <StyledText fontStyle={'TextLg_Bold'} text={message ?? ''} />
          {hasTimer && timer && <StyledTimer fontStyle={'TextLg_Bold'} text={timer} />}
        </Wrap>
      )}
    </>
  );
};

const StyledTimer = styled(Text)`
  font-size: 2.5em;
`;
const StyledText = styled(Text)`
  font-size: 1.5em;
`;
const Wrap = styled.div`
  position: absolute;
  z-index: 2;
  /* padding: 0 6.6875em; */
  gap: 0.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.5em;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 0) 95%);
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.25));
  will-change: filter;
  top: 50%;
  left: 50%;
  border-radius: 12.5em;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    border-radius: 12px;
    height: 120px;
    z-index: 100;
    gap: 12px;
    top: 50%;
    transform: translate(-50%, -85%);
  }
`;

export default TableMessage;
