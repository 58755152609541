import { useEffect, useMemo, useState } from 'react';
import { BetProps, PresetBetType } from './types';
import { useTableContext } from '../../../../hooks/TableProvider';
import { GameRound, PlayerAction } from '../../../../store/slices/streamingTypes';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import { addBigNumbers, divideBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../../utils/BigNumberUtil';
import { numberToDisplayString } from '../../../../utils/StringUtil';

interface PresetButton {
  text: string;
  value: PresetBetType;
  disabled?: boolean;
  multiplier?: number;
  percent?: number;
}

export const usePresets = ({ setBetAmount, betAmount, onBetting, onCall, minBetForRaise }: BetProps) => {
  const [isBetting, setIsBetting] = useState(false);
  const { adjustBetToBetUnit, adjustBetToBetUnitForRaise, tableCallAmount, totalPot, minRaiseAmount, maxAllInAmount, players, bbAmount, handRound, amountToCall, myPlayerData, chipDecimal } = useTableContext();
  const userTableSetting = useUserTableSetting();
  const [displayBet, setDisplayBet] = useState('0');

  const tablePot = useMemo(() => totalPot ?? 0, [totalPot]);
  const minRaise = useMemo(() => minRaiseAmount ?? 0, [minRaiseAmount]);
  const maxRaise = useMemo(() => maxAllInAmount ?? 0, [maxAllInAmount]);

  const isOpeningBet = useMemo(() => handRound === GameRound.PRE_FLOP && players?.every(player => !player.lastAction || ![PlayerAction.BET, PlayerAction.CALL, PlayerAction.RAISE, PlayerAction.ALLIN].includes(player.lastAction)), [handRound, players]);

  const calculateWRA = (percent: number) => {
    const totalToPot = addBigNumbers(tablePot, amountToCall ?? 0);

    const adjustedContribution = multiplyBigNumbers(totalToPot, percent);
    const scaledContribution = divideBigNumbers(adjustedContribution, 0.1);
    const finalContribution = multiplyBigNumbers(scaledContribution, 0.1);

    return subtractBigNumbers(addBigNumbers(totalToPot, finalContribution), tablePot).toNumber();
  };

  const getPresetButtons = () => {
    const baseButtons: PresetButton[] = isOpeningBet
      ? [
          { text: '2BB', value: '2bb', multiplier: 2 },
          { text: '3BB', value: '3bb', multiplier: 3 },
          { text: '4BB', value: '4bb', multiplier: 4 },
          { text: 'POT', value: 'pot', percent: 1 }
        ]
      : [
          { text: '33%', value: '33', percent: 0.33 },
          { text: '50%', value: '50', percent: 0.5 },
          { text: '70%', value: '70', percent: 0.7 },
          { text: '100%', value: 'pot', percent: 1 }
        ];

    return baseButtons.map(button => ({
      ...button,
      disabled: button.multiplier ? multiplyBigNumbers(bbAmount ?? 0, button.multiplier).toNumber() > (myPlayerData?.stack ?? 0) : calculateWRA(button.percent ?? 1) < addBigNumbers(minRaise, myPlayerData?.callAmount ?? 0).toNumber()
    }));
  };

  const presetButtons = useMemo(getPresetButtons, [isOpeningBet, totalPot, amountToCall, bbAmount, myPlayerData?.stack, minRaise, calculateWRA]);

  const updateBetAndDisplay = (calculatedBet: number) => {
    const adjustedBet = adjustBetToBetUnit(calculatedBet);
    setBetAmount(adjustedBet);
    setDisplayBet(numberToDisplayString(adjustedBet, undefined, userTableSetting?.isBlindView, bbAmount));
  };

  const onPresetButtonClick = (type: PresetBetType) => {
    if (tableCallAmount === undefined) return;
    const button = presetButtons.find(button => button.value === type);
    if (!button) return;
    updateBetAndDisplay(button.multiplier ? multiplyBigNumbers(bbAmount ?? 0, button.multiplier).toNumber() : calculateWRA(button.percent ?? 1));
  };

  const onSliderChanged = (value: number) => {
    const isBlindView = false;
    const calculatedAmount = isBlindView ? multiplyBigNumbers(value, bbAmount ?? 0).toNumber() : value;
    setDisplayBet(numberToDisplayString(calculatedAmount, undefined, userTableSetting?.isBlindView, bbAmount));
    setBetAmount(Math.min(calculatedAmount, addBigNumbers(maxRaise, myPlayerData?.callAmount ?? 0).toNumber()));
  };

  const onInputChanged = (v: string) => {
    const decimalPlaces = userTableSetting?.isBlindView ? 1 : chipDecimal ?? 0;
    const regex = decimalPlaces > 0 ? /[^0-9.]+/g : /[^0-9]+/g;
    let inputValue = v.replace(regex, '').replace(/(\..*)\./g, '$1');

    if (decimalPlaces > 0) {
      const parts = inputValue.split('.');
      if (parts.length > 2) {
        inputValue = `${parts[0]}.${parts.slice(1).join('')}`;
      }
      const secondParts = inputValue.split('.');
      if (secondParts[1]?.length > decimalPlaces) {
        inputValue = `${secondParts[0]}.${secondParts[1].slice(0, decimalPlaces)}`;
      }
    }
    setDisplayBet(inputValue);
    const amount = multiplyBigNumbers(inputValue, userTableSetting?.isBlindView ? bbAmount : 1).toNumber();
    setBetAmount(Math.min(amount, addBigNumbers(maxRaise, myPlayerData?.callAmount ?? 0).toNumber()));
  };

  useEffect(() => {
    setDisplayBet(numberToDisplayString(betAmount, undefined, userTableSetting?.isBlindView, bbAmount));
  }, [userTableSetting?.isBlindView, bbAmount]);

  const handleBlurInput = () => {
    const adjustedBet = adjustBetToBetUnitForRaise(betAmount);
    setDisplayBet(numberToDisplayString(adjustedBet, undefined, userTableSetting?.isBlindView, bbAmount));
    setBetAmount(adjustedBet);
  };

  const handleEnter = () => {
    if (isBetting) return;
    setIsBetting(true);
    if (Number(betAmount || 0) < (minBetForRaise || 0)) {
      onCall?.();
    } else {
      handleBlurInput();
      onBetting?.();
    }
    setIsBetting(false);
  };

  return {
    displayBet,
    presetButtons,
    onSliderChanged,
    onInputChanged,
    onPresetButtonClick,
    handleBlurInput,
    handleEnter
  };
};
