import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import PreviewSeat from '../room/seat/PreviewSeat';
import { bpSocketService, SocketNamespace } from '../../../services/BpWebSocketService';
import { TableEventPacketWithDelay } from '../../../store/slices/streamingTypes';
import { ModalType, showModal, showModalWithRequest } from '../../../store/slices/modalSlice';

import useTable from '../../../hooks/useTable';
import usePageVisibility from '../../../hooks/usePageVisibility';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useCountryList } from '../../../hooks/useCountryList';
import { useAuthContext } from '../../../hooks/AuthContext';

import BaseButton from '../../common/button/BaseButton';
import useOpenTable from '../../../hooks/useOpenTable';
import tournamentSlice from '../../../store/slices/tournamentSlice';
import useDevice from '../../../hooks/useDevice';
import { toFixedFloor } from '../../../utils/StringUtil';

interface TablePreviewProps {
  tournamentId: string;
  shareCode: string;
}

const TournamentTablePreview: React.FC<TablePreviewProps> = ({ tournamentId, shareCode }) => {
  const { isAuthorized } = useAuthContext();
  const isPageVisible = usePageVisibility();
  const { user } = useUserProfile();
  const countries = useCountryList();
  const { tableSnapshot, mySeatId, isTournamentTable } = useTable(undefined, shareCode);
  const [isLoading, setLoading] = useState(true);
  const [dataQueue, setDataQueue] = useState<TableEventPacketWithDelay[]>([]);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const { openTable } = useOpenTable();
  const { isMobile } = useDevice();

  const calculateUserPositionsAsPercent = useCallback((numUsers: number, type: 'horizontal' | 'vertical' = 'horizontal') => {
    const positions = [];
    const step = (2 * Math.PI) / numUsers;
    for (let i = 0; i < numUsers; i++) {
      const theta = step * i + Math.PI / 2;
      const xPercent = 50 + 50 * Math.cos(theta);
      const yPercent = 50 + 50 * Math.sin(theta);
      const x = type === 'vertical' ? (xPercent > 51 ? xPercent + 7 : xPercent < 49 ? xPercent - 7 : xPercent) : xPercent > 90 ? xPercent + 3 : xPercent < 10 ? xPercent - 3 : xPercent;
      const y = type === 'horizontal' ? (yPercent > 51 ? yPercent + 7 : yPercent < 49 ? yPercent - 7 : yPercent) : yPercent;
      positions.push({ x: Math.min(Math.max(0, x), 100), y: Math.min(Math.max(0, y), 99) });
    }
    return positions;
  }, []);

  const positions = calculateUserPositionsAsPercent(9, isMobile ? 'vertical' : 'horizontal');

  const handleLobbyEvent = useCallback((tableData: any) => {
    const packetWithDelay: TableEventPacketWithDelay = { ...tableData };
    setDataQueue(prevQueue => [...prevQueue, packetWithDelay]);
    setLoading(false);
  }, []);

  const showAuthModal = useCallback(() => {
    dispatch(showModal(ModalType.AuthModal));
  }, [dispatch]);

  const showCreateTableModal = useCallback(() => {
    dispatch(showModalWithRequest({ type: ModalType.CreateTableModal, data: { chip: undefined } }));
  }, [dispatch]);

  const onCreateTableClick = useCallback(() => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  }, [isAuthorized, showAuthModal, showCreateTableModal]);

  const navigateTableWithSeatId = useCallback(
    (seatId?: number) => {
      if (!shareCode || mySeatId !== undefined) return;
      const newTab = openTable();
      if (!newTab) return;
      newTab.onload = () => {
        newTab.postMessage({ type: 'joinTable', seatId });
      };
    },
    [mySeatId, shareCode]
  );

  const processQueue = useCallback(
    async (useLatest: boolean) => {
      if (dataQueue.length > 0 && !processing) {
        setProcessing(true);
        if (useLatest) {
          const lastEvent = dataQueue[dataQueue.length - 1];
          dispatch(tournamentSlice.actions.storeTournamentTableSnapshot(lastEvent));
          setDataQueue([]);
        } else {
          const currentEvent = dataQueue[0];
          dispatch(tournamentSlice.actions.storeTournamentTableSnapshot(currentEvent));
          setDataQueue(queue => queue.slice(1));
        }
        setProcessing(false);
      }
    },
    [dataQueue, processing, dispatch]
  );

  useEffect(() => {
    if (dataQueue.length > 0 && !processing) {
      processQueue(false).then();
    }
  }, [dataQueue, processing, processQueue]);

  useEffect(() => {
    bpSocketService.off(SocketNamespace.GAME_TABLE);
    bpSocketService.connect(() => {
      bpSocketService.on(SocketNamespace.GAME_TABLE, handleLobbyEvent);
      bpSocketService.tournamentTableJoin(shareCode);
    });
    return () => {
      bpSocketService.off(SocketNamespace.GAME_TABLE);
    };
  }, [shareCode, isPageVisible, handleLobbyEvent]);

  // {toFixedFloor(tournamentPlayer.chips, 0, false, true)}

  return (
    <StyledWrap>
      <StyledTable>
        {shareCode &&
          positions.map((pos, i) => <PreviewSeat key={i} isTournamentTable={isTournamentTable} shareCode={shareCode} isAlreadySeated={mySeatId !== undefined} seatId={i} pos={pos} onClick={() => navigateTableWithSeatId(i)} tableSnapshot={tableSnapshot} user={user} countries={countries} />)}
        <OpenButton>
          <BaseButton
            textStyle="TextSmall_Semibold"
            size="btn40"
            color="gray"
            textColor="white"
            onClick={() => {
              openTable(shareCode, tournamentId);
            }}
          >
            Open Table
          </BaseButton>
        </OpenButton>
      </StyledTable>
    </StyledWrap>
  );
};

const OpenButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105px;
`;

const StyledWrap = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 50px 18px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

const StyledTable = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  border-radius: 1000px;
  border: 2px solid ${({ theme }) => theme.colors.gray500};
  background: linear-gradient(180deg, #1d2939 0%, #29364a 52.08%, #1d2939 100%);
  aspect-ratio: 377/236;
  @media (max-width: 1024px) {
    max-width: 377px;
  }
  @media (max-width: 768px) {
    aspect-ratio: 236/377;
  }
`;

export default TournamentTablePreview;
