import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_PASSWORD } from '../settings/CreateTableTemplate';
import LinearLayout from '../../layout/LinearLayout';
import { dismissModal, ModalStatus, ModalType, setModalResponse } from '../../../store/slices/modalSlice';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { LeftText } from '../../common/text/styles';
import TextInput from '../../common/input/TextInput';
import { bpSocketService } from '../../../services/BpWebSocketService';
import { RootState } from '../../../store/store';
import { selectedTableErrorType, selectPasswordVerify } from '../../../store/slices/streaming';
import { ErrorTypes, getErrorMessageByType } from '../../../store/api/api';
import { waitForReduxStateChange } from '../../../utils/Util';
import { useTableContext } from '../../../hooks/TableProvider';
import { useTranslation } from 'react-i18next';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';

interface PasswordVerifyModalProps {
  isTournament?: boolean;
}

const PasswordVerifyModal: React.FC<PasswordVerifyModalProps> = ({ isTournament }) => {
  const dispatch = useDispatch();
  const { tableId, tableSettings, myUserData } = useTableContext();
  const { tournamentInfo } = useTournamentLobby();
  const { t } = useTranslation();
  const errorType = useSelector((state: RootState) => selectedTableErrorType(state, tableId));
  const { modalRequest } = useSelector((state: RootState) => state.modalStateSlice);
  const [isPasswordLimited, setPasswordLimited] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordFulfilled, setPasswordFulfilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onRequestClose = (isCancel: boolean = true, sendData = modalRequest?.data) => {
    dispatch(setModalResponse({ type: ModalType.PasswordVerifyModal, status: isCancel ? ModalStatus.Cancel : ModalStatus.Approve, data: sendData }));
    dispatch(dismissModal());
  };

  const reconnectByError = () => {
    onRequestClose(true, { reconnect: myUserData?.passwordReentryAt === undefined });
  };

  const verifyPassword = () => {
    waitForReduxStateChange(
      (state: RootState) => selectPasswordVerify(state, Number(tableId)),
      isVerified => isVerified
    )
      .then(() => {
        onRequestClose(false);
      })
      .catch(error => {});
    bpSocketService.verifyPassword(password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, ''); // Remove non-numeric characters
    if (newValue.length > MAX_PASSWORD) {
      newValue = newValue.substring(0, MAX_PASSWORD);
    }
    setPasswordFulfilled(newValue.length === 4);
    setPassword(newValue);
  };

  useEffect(() => {
    const checkLimitExpiration = () => {
      if (myUserData?.passwordReentryAt) {
        const expiresIn = new Date(myUserData?.passwordReentryAt).getTime() - Date.now();
        const seconds = Math.floor(expiresIn / 1000);
        setPasswordLimited(expiresIn > 0);
        setErrorMessage(t('MESSAGE.PasswordErrorSeconds', { seconds: seconds }));
      } else {
        setPasswordLimited(myUserData?.passwordReentryAt !== undefined || errorType === ErrorTypes.PASSWORD_EXCEEDED);
        setErrorMessage(t('MESSAGE.PasswordErrorMinutes'));
      }
    };
    const interval = setInterval(() => {
      checkLimitExpiration();
    }, 1000);
    checkLimitExpiration();
    return () => clearInterval(interval);
  }, [myUserData, errorType]);

  return (
    <Background>
      <LinearLayout borderRadius={12} gap={16}>
        <HorizontalLayout margin={'0 0 16px 0'}>
          <Text fontStyle={'TextXl_Semibold'} text={t('CASHGAME.Password')} textAlign={'start'} />
          <XCloseIcon
            onClick={() => {
              if (isPasswordLimited) {
                reconnectByError();
              }
              onRequestClose();
            }}
          />
        </HorizontalLayout>
        {isPasswordLimited ? (
          <Text marginTop={32} marginBottom={16} fontStyle={'TextXl_Medium'} text={errorMessage} textColor={'white'} />
        ) : (
          <>
            <Text fontStyle={'DisplayXs_Bold'} text={isTournament ? tournamentInfo?.static.title : tableSettings?.name} />
            <LinearLayout gap={4} marginTop={16}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <LeftText fontStyle={'TextMd_Medium'} text={t('CASHGAME.Password')} />
                <Text fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput onChange={handlePasswordChange} value={password} />
              {errorType && <ErrorMessage>{getErrorMessageByType(errorType)}</ErrorMessage>}
            </LinearLayout>
          </>
        )}
        <VerifyButton
          isValid={isPasswordLimited || isPasswordFulfilled}
          onClick={() => {
            if (isPasswordLimited) {
              reconnectByError();
            } else {
              if (isPasswordFulfilled) {
                verifyPassword();
              }
            }
          }}
        >
          {isPasswordLimited ? `${t('BUTTON.Okay')}` : `${t('BUTTON.Continue')}`}
        </VerifyButton>
      </LinearLayout>
    </Background>
  );
};

const Background = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 80vw;
  }
`;

const VerifyButton = styled.button<{ isValid: boolean }>`
  ${({ theme }) => theme.textStyles.TextLg_Semibold};
  width: 100%;
  height: 60px;
  background-color: ${({ isValid, theme }) => (isValid ? theme.colors.primary600 : theme.colors.gray700)};
  border-radius: 8px;
  color: ${({ isValid, theme }) => (isValid ? theme.colors.white : theme.colors.gray500)};
  border: none;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  margin-top: 4px;
`;

export default PasswordVerifyModal;
