import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { selectTournamentLobbyPacket } from '../store/slices/tournamentSlice';
import { useUserProfile } from './useUserProfile';
import { getBigNumber } from '../utils/BigNumberUtil';
import useAssets from './useAssets';
import { TournamentStatus } from '../store/slices/tournamentTypes';
import useCountdown from './useCountdown';

export const useTournamentLobby = (id?: string) => {
  const { user } = useUserProfile();
  const { getNetwork } = useAssets();

  const tournamentMatch = useMemo(() => location.pathname.match(/\/tournament\/([^/]+)/), [location.pathname]);
  const tournamentId = id || (tournamentMatch ? tournamentMatch[1] : null);

  const tournamentData = useSelector((state: RootState) => (tournamentId ? selectTournamentLobbyPacket(state, tournamentId) : null));
  const tournamentInfo = tournamentData?.payload?.snapshot?.information;
  const tournamentStructure = tournamentData?.payload?.snapshot?.structure;

  const isRegistered = useMemo(() => {
    return tournamentData?.payload?.snapshot?.players?.some(player => player.userId === user?.id);
  }, [user, tournamentData]);

  const isRegisterEnable = useMemo(() => {
    const statusList = isRegistered ? [TournamentStatus.CREATED, TournamentStatus.REGISTERING] : [TournamentStatus.CREATED, TournamentStatus.REGISTERING, TournamentStatus.PREPARING, TournamentStatus.LATE_REGISTRATION];
    return statusList.includes(tournamentInfo?.dynamic?.status ?? TournamentStatus.UNKNOWN);
  }, [isRegistered, tournamentInfo]);

  const isTableAccessible = useMemo(() => {
    const statusList = [TournamentStatus.PREPARING, TournamentStatus.LATE_REGISTRATION, TournamentStatus.RUNNING, TournamentStatus.BREAK];
    return isRegistered && statusList.includes(tournamentInfo?.dynamic?.status ?? TournamentStatus.UNKNOWN);
  }, [isRegistered, tournamentInfo]);

  const myTournamentTableShareCode = useMemo(() => {
    return tournamentData?.payload?.snapshot?.players?.find(player => player.userId === user?.id)?.shareCode;
  }, [user, tournamentData?.payload?.snapshot?.players]);

  // 현재 블라인드와 관련된 정보
  const currentBlindId = tournamentInfo?.dynamic?.currentBlindId;
  const currentBlindStartedAt = tournamentInfo?.dynamic?.currentBlindStartedAt ?? '';
  const currentBlindInfo = tournamentStructure?.blindSchedule.find(blind => blind.blindId === currentBlindId?.toString());
  const currentBlindEndAt = useMemo(() => new Date(currentBlindStartedAt).getTime() + (currentBlindInfo?.interval ?? 1) * 60 * 1000, [currentBlindStartedAt, currentBlindInfo]);

  // 다음 블라인드 및 브레이크 계산
  const { hasNextBlind, hasNextBreak, nextBreakStartedAt, nextBreakEndedAt } = useMemo(() => {
    let hasNextBlind = false;
    let hasNextBreak = false;
    let nextBreakStartedAt: number | null = null;
    let nextBreakEndedAt: number | null = null;

    if (tournamentStructure?.blindSchedule && currentBlindInfo) {
      const currentIndex = tournamentStructure.blindSchedule.findIndex(blind => blind.blindId === currentBlindInfo.blindId);

      if (currentIndex !== -1) {
        // 다음 블라인드 확인
        hasNextBlind = tournamentStructure.blindSchedule.slice(currentIndex + 1).some(blind => blind.type === 'BLIND');

        // 다음 브레이크 확인
        const nextBreak = tournamentStructure.blindSchedule.slice(currentIndex + 1).find(blind => blind.type === 'BREAK');

        if (nextBreak) {
          hasNextBreak = true;

          // 브레이크 시간 계산
          const breakIndex = tournamentStructure.blindSchedule.indexOf(nextBreak);
          const breakStartedAt = new Date(currentBlindStartedAt).getTime() + tournamentStructure.blindSchedule.slice(currentIndex + 1, breakIndex + 1).reduce((acc, blind) => acc + blind.interval * 60 * 1000, 0);

          nextBreakStartedAt = breakStartedAt;
          nextBreakEndedAt = breakStartedAt + nextBreak.interval * 60 * 1000;
        }
      }
    }

    return { hasNextBlind, hasNextBreak, nextBreakStartedAt, nextBreakEndedAt };
  }, [currentBlindInfo, currentBlindStartedAt, tournamentStructure]);

  return useMemo(
    () => ({
      isRegistered,
      isRegisterEnable,
      myTournamentTableShareCode,
      tournamentId,
      tournamentData,
      tournamentInfo,
      tournamentStructure,
      tournamentPrizePool: tournamentData?.payload?.snapshot?.prizePool,
      tournamentPlayers: tournamentData?.payload?.snapshot?.players,
      tournamentSummary: tournamentData?.payload?.snapshot?.summary,
      tournamentTables: tournamentData?.payload?.snapshot?.tables,
      tournamentInTheMoney: tournamentData?.payload?.snapshot?.inTheMoney,
      totalPrizeAmount: tournamentData?.payload?.snapshot?.prizePool?.pool?.reduce((total, prize) => total + getBigNumber(prize.prizeAmount).toNumber(), 0) ?? 0,
      tournamentStatus: tournamentInfo?.dynamic?.status,
      tournamentAsset: getNetwork(tournamentInfo?.static?.assetName ?? 'USDT'),
      currentBlindInfo,
      hasNextBlind,
      hasNextBreak,
      currentBlindEndAt,
      nextBreakStartedAt,
      nextBreakEndedAt,
      isTableAccessible
    }),
    [tournamentId, tournamentData, tournamentInfo, tournamentStructure, isRegistered, isRegisterEnable, myTournamentTableShareCode, getNetwork, currentBlindInfo, hasNextBlind, hasNextBreak, currentBlindEndAt, nextBreakStartedAt, nextBreakEndedAt]
  );
};
