import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TournamentFinished } from '../../common/icon';
import { TournamentStatus } from '../../../store/slices/tournamentTypes';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';

interface TournamentStateTimerProps {
  endAt?: string; // ISO format date
  size?: number;
}

const TournamentTimer: React.FC<TournamentStateTimerProps> = ({ endAt, size = 90 }) => {
  const { tournamentStatus } = useTournamentLobby();

  // 상수 정의 (렌더링 시점에서 고정)
  const endTime = useMemo(() => new Date(endAt ?? '').getTime(), [endAt]);
  const startTime = useMemo(() => Date.now(), []);
  const totalTime = endTime - startTime;

  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [isAnimating, setIsAnimating] = useState(false);

  const radius = (size / 2) * 0.8;
  const circumference = 2 * Math.PI * radius;

  // 남은 시간 업데이트
  useEffect(() => {
    setIsAnimating(true);

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime >= endTime) {
        setTimeLeft(0); // 종료
        clearInterval(intervalId);
      } else {
        setTimeLeft(endTime - currentTime);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  // 시간 포맷 함수
  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;
    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
  };

  return (
    <>
      {tournamentStatus === TournamentStatus.CLOSED ? (
        <CircularTimer>
          <TournamentFinished />
        </CircularTimer>
      ) : (
        <CircularTimer>
          <svg width={size} height={size}>
            <circle cx={size / 2} cy={size / 2} r={radius} stroke={Colors.primary600} strokeWidth="6" fill="transparent" />
            <circle
              cx={size / 2}
              cy={size / 2}
              r={radius}
              stroke={Colors.primary200}
              strokeWidth="6"
              fill="transparent"
              strokeDasharray={circumference}
              strokeDashoffset={(timeLeft / totalTime) * circumference}
              strokeLinecap="round"
              style={{
                transition: isAnimating ? 'stroke-dashoffset 1s linear' : 'none',
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%'
              }}
            />
          </svg>
          <TimeDisplay>{formatTime(timeLeft)}</TimeDisplay>
        </CircularTimer>
      )}
    </>
  );
};

const CircularTimer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 10px 0 0;
  @media (max-width: 1024px) {
    margin: 0 0 6px 0;
  }
`;

const TimeDisplay = styled.div`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: white;
`;

export default TournamentTimer;
