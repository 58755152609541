import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';

export const TableSection = styled.div<{ flex?: number; maxHeight?: number }>`
  flex: ${({ flex }) => flex || 1};
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ maxHeight }) => maxHeight && `min-height: ${maxHeight}px;`}
    background-color: ${({ theme }) => theme.colors.gray800};
  overflow: hidden;
  flex-shrink: 0;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledTableHeader = styled.div`
  //position: absolute;
  //top: 0;
  display: grid;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray750};
  box-sizing: border-box;
`;

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  ////padding-top: 40px;
  //width: 100%;
  ////grid-auto-rows: min-content;
  //box-sizing: border-box;
  //overflow-y: scroll;
  //flex: 1;

  //gap: 8px;
  padding: 8px 0;
  flex: 1;
  overflow-y: auto;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const StyledRowHeader = styled.div<{ textStyles?: keyof typeof TextStyles }>`
  display: grid;
  ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextSmall_Medium)}
  color: ${({ theme }) => theme.colors.gray400};
  padding: 8px 0;
  @media (max-width: 768px) {
    ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextXs_Medium)}
  }
`;

export const StyledRow = styled.div<{ selected: boolean; textStyles?: keyof typeof TextStyles }>`
  display: grid;
  cursor: pointer;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.table_selected : 'transparent')};
  ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextMd_Medium)}
  color: ${({ theme }) => theme.colors.white};
  line-height: 20px;
  padding: 4px 0;
  @media (max-width: 768px) {
    padding: 2px 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.table_selected};
  }
`;

interface StyledCellProps {
  color?: keyof typeof Colors; // Add color prop
}

export const StyledCell = styled.div<StyledCellProps>`
  display: flex;
  justify-content: center;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  margin-top: 4px;
`;
