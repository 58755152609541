import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTableList, selectTablePacket } from '../store/slices/streaming';
import { RootState } from '../store/store';
import { TableInfo } from '../store/api/responseTypes';
import { EventHeader, EventName, EventPayload, TableSnapshot, TableUpdate } from '../store/slices/streamingTypes';
import { useTableId } from './useTableId';
import { selectTournamentTablePacket } from '../store/slices/tournamentSlice';
import { useLocation } from 'react-router-dom';
import useRouteMatch from './useRouteMatch';

interface UseTablePacketReturn {
  tableId: number | undefined;
  name: EventName;
  header: EventHeader;
  payload: EventPayload;
  tableSnapshot: TableSnapshot;
  update: TableUpdate;
}

export const useTablePacket = (id?: number, manualShareCode?: string): UseTablePacketReturn => {
  const location = useLocation();

  const { tournamentId, shareCode } = useRouteMatch();

  const code = useMemo(() => {
    return manualShareCode ?? shareCode ?? null;
  }, [manualShareCode, shareCode]);

  const tableId = useTableId(id);
  const tablePacket = tournamentId !== null && code !== null ? useSelector((state: RootState) => selectTournamentTablePacket(state, code)) : useSelector((state: RootState) => selectTablePacket(state, Number(tableId)));
  
  const { name, header, payload } = tablePacket;
  const tableSnapshot = payload?.snapshot || [];
  const update = payload?.update || {};

  return useMemo(
    () => ({
      tableId,
      name,
      header,
      payload,
      tableSnapshot,
      update
    }),
    [tableId, name, header, payload, tableSnapshot, update, location.pathname]
  );
};

export const useTableList = (): TableInfo[] => {
  const tableList = useSelector(selectTableList);
  return useMemo(() => {
    return tableList;
  }, [tableList]);
};
