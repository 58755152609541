import React, { useEffect, useMemo } from 'react';
import { useUserProfile } from './useUserProfile';
import { useTablePacket } from './useTableInfo';
import { HeaderAction, HeaderType } from 'src/store/slices/streamingTypes';
import { SeatStatus, TableStatus } from '../store/api/responseTypes';
import { numberToDisplayString } from '../utils/StringUtil';
import _ from 'lodash';
import { DELAY_WINNING_DISPLAY } from '../utils/AnimationUtil';
import { useUserTableSetting } from './useUserTableSetting';
import { addBigNumbers, divideBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../utils/BigNumberUtil';
import { showFailToast, showSuccessToast } from '../components/common/toast/BpToast';
import i18n from 'i18next';

const useTable = (id?: number, shareCode?: string) => {
  const channel = new BroadcastChannel('base-channel');
  const { user } = useUserProfile();

  const { name, header, tableSnapshot, tableId, payload, update } = useTablePacket(id, shareCode);

  const gameType = useMemo(() => header?.gameType, [header]);
  const gameFormat = useMemo(() => header?.gameFormat, [header]);
  const isTournamentTable = useMemo(() => header?.gameFormat === 'MTT', [header]);

  const userTableSetting = useUserTableSetting();
  const [winnerType, setWinnerType] = React.useState<'villain' | 'hero' | undefined>();
  const tableSettings = tableSnapshot?.setting;
  const tableAssetInfo = tableSettings?.asset;
  const betUnit = React.useMemo(() => tableSnapshot?.setting?.betUnit ?? 1, [tableSnapshot?.setting]);
  const chipDecimal = betUnit?.toString()?.split('.')[1]?.length ?? 0;
  const tableUsers = tableSnapshot?.users;
  const players = tableSnapshot?.hand?.players;
  const seats = tableSnapshot?.seats;
  const blinds = tableSettings?.blindAmount ?? tableSnapshot?.hand?.blinds;
  const winners = tableSnapshot?.hand?.winners;
  const totalPot = tableSnapshot?.hand?.pot?.total;
  const totalPotForDisplay = React.useMemo(() => {
    const getTotalPot = () => {
      if (userTableSetting?.isBlindView) {
        return divideBigNumbers(tableSnapshot?.hand?.pot?.total ?? 0, tableSettings?.blindAmount!.big ?? 1).toNumber();
      } else {
        return tableSnapshot?.hand?.pot?.total ?? 0;
      }
    };
    const total = getTotalPot();
    return `${numberToDisplayString(total, chipDecimal, userTableSetting.isBlindView, 1)}${userTableSetting.isBlindView ? ' BB' : ''}`;
  }, [tableSnapshot?.hand?.pot?.total, tableSettings?.blindAmount, userTableSetting.isBlindView, chipDecimal]);

  const sidePots = React.useMemo(() => tableSnapshot?.hand?.pot?.sides, [tableSnapshot?.hand?.pot?.sides]);
  const pot = React.useMemo(() => subtractBigNumbers(totalPot || 0, players?.reduce((acc, player) => addBigNumbers(acc, player.callAmount || 0).toNumber(), 0)).toNumber(), [totalPot, players]); // TODO 백엔드에서 팟을 바로 줬으면 좋겠다 (확인)
  const tableCallAmount = React.useMemo(() => tableSnapshot?.hand?.tableCallAmount, [tableSnapshot?.hand?.tableCallAmount]);
  const round = React.useMemo(() => tableSnapshot?.hand?.round, [tableSnapshot?.hand?.round]);
  const tableStatus = tableSnapshot?.status;
  const communityCards = tableSnapshot.hand?.communityCards;
  const handId = tableSnapshot.hand?.handId;
  const updateInfo = payload?.update;
  const isLastHandAction = React.useMemo(() => payload?.update?.isLastHandEvent ?? false, [payload?.update?.isLastHandEvent]);
  const needsChipGathering = React.useMemo(() => isLastHandAction && players?.some(player => (player.callAmount ?? 0) > 0), [isLastHandAction]);

  const mySeatData = React.useMemo(() => seats?.find(seat => seat.userId === user?.id), [seats, user?.id]);
  const mySeatStatus = mySeatData?.status ?? SeatStatus.STAND;
  const myUserData = React.useMemo(() => tableUsers?.find(tableUser => tableUser.userId === user?.id), [tableUsers, user?.id]);
  const myPlayerData = React.useMemo(() => players?.find(player => player.userId === user?.id), [players, user?.id]);
  const myStack = React.useMemo(() => myPlayerData?.stack ?? myUserData?.stack ?? 0, [myUserData, myPlayerData]);
  const isOwner = React.useMemo(() => {
    return user?.id !== undefined && tableSettings?.owner === user?.id;
  }, [tableSettings?.owner, user?.id]);
  const isMyTurn = React.useMemo(() => myPlayerData?.status === 'ASK', [myPlayerData?.status]);

  const handStrength = myPlayerData?.handStrength;
  const myAllowedActions = React.useMemo(() => myPlayerData?.allowedActions ?? [], [myPlayerData?.allowedActions?.join(',')]);
  const minRaiseAmount = React.useMemo(() => myPlayerData?.minRaiseAmount, [myPlayerData?.minRaiseAmount]);
  const amountToCall = React.useMemo(() => myPlayerData?.amountToCall, [myPlayerData?.amountToCall]);
  const reservedSitOut = React.useMemo(() => mySeatData?.reservedSeatStatus === SeatStatus.SEAT_OUT || mySeatData?.reservedSeatStatus === SeatStatus.STAND, [mySeatData?.reservedSeatStatus]);
  const availableSeats = !mySeatData?.status ? subtractBigNumbers(9, seats?.length).toNumber() : 0;
  const canReserve = React.useMemo(() => availableSeats > 0 || ((myUserData?.stack ?? 0) > 0 && (mySeatData?.status === undefined || mySeatData?.status === SeatStatus.STAND)), [availableSeats, myUserData, mySeatData]);
  const maxAllInAmount = React.useMemo(() => {
    return myPlayerData?.maxAllInAmount !== undefined && myPlayerData?.stack !== undefined ? (myPlayerData?.maxAllInAmount > myPlayerData?.stack ? myPlayerData?.maxAllInAmount : myPlayerData?.stack) : undefined;
  }, [myPlayerData?.maxAllInAmount, myPlayerData?.stack]);
  const chipRequests = React.useMemo(() => (isOwner ? tableSnapshot.addChipsRequests ?? [] : []), [tableSnapshot.addChipsRequests, isOwner]);
  const myUserTotalStack = addBigNumbers(myUserData?.stack, myUserData?.additionalStack).toNumber();
  const isAddChipAvailable = React.useMemo(() => {
    const maxBuyInStack = tableSettings?.buyIn.max ?? myUserTotalStack;
    return mySeatData !== undefined && myUserTotalStack < maxBuyInStack && tableSnapshot.addChipsRequests?.find(request => request.userId === myUserData?.userId) === undefined;
  }, [tableSnapshot.addChipsRequests, myUserData, mySeatData]);

  const myTableAction = React.useMemo(() => {
    if (user?.id === update?.requestUserId || user?.id === update?.requesterUserId) {
      return header?.action;
    } else {
      return undefined;
    }
  }, [header, user, update]);

  const roles = React.useMemo(() => tableSnapshot?.hand?.roles, [tableSnapshot?.hand?.roles]);
  const isTieGame = React.useMemo(() => tableSnapshot?.hand?.players?.every(player => (player?.tieRate ?? 0) === 100), [tableSnapshot?.hand?.players]);
  const ask = React.useMemo(() => tableSnapshot?.hand?.ask, [tableSnapshot?.hand?.ask]);

  const canStart = useMemo(() => isOwner && tableStatus === TableStatus.WAITING, [isOwner, seats, tableStatus]);
  const canSeatBackIn = useMemo(() => (myUserData?.stack ?? 0) > 0 && mySeatStatus === SeatStatus.SEAT_OUT, [myUserData, mySeatStatus]);
  const canBuyIn = useMemo(() => tableStatus !== TableStatus.CLOSED && (mySeatStatus === SeatStatus.SEAT_OUT || mySeatStatus === SeatStatus.RESERVED) && isAddChipAvailable, [myUserData, mySeatStatus, tableStatus, isAddChipAvailable]);
  const prepareStart = useMemo(() => (seats?.filter(seat => seat.status === SeatStatus.SEAT_IN)?.length || 0) > 1, [seats, canStart]);

  const tournamentTableInformation = useMemo(() => tableSnapshot?.information, [tableSnapshot?.information]);
  const tournamentInformation = useMemo(() => tableSnapshot?.tournamentInfo, [tableSnapshot?.tournamentInfo]);
  const tournamentShareCode = useMemo(() => tableSnapshot?.shareCode, [tableSnapshot?.shareCode]);

  const tournamentSummary = tournamentInformation?.summary;
  const blindInfo = tournamentInformation?.blindInfo;
  const entrants = tournamentInformation?.entrants;
  const prizePool = tournamentInformation?.prizePool;
  const prizeInfo = tournamentSummary?.prizeInfo;

  const adjustBetToBetUnit = (betAmount: number): number => {
    const minAmount = minRaiseAmount || 0;
    const maxAmount = maxAllInAmount || 0;
    const minBetSize = Math.min(minAmount, maxAmount);

    const adjustedBet = multiplyBigNumbers(Math.floor(divideBigNumbers(betAmount, betUnit).toNumber()), betUnit).toNumber();

    const nextPossibleBet = addBigNumbers(adjustedBet, betUnit).toNumber();

    if (maxAmount < nextPossibleBet) {
      // 다음 베팅 가능 단위보다 올인 가능한 금액이 작은 경우, 베팅 단위 무시하고 올인
      return maxAmount;
    }

    return Math.max(minBetSize, adjustedBet);
  };

  // for raise to
  const adjustBetToBetUnitForRaise = (betAmount: number): number => {
    const minAmount = minRaiseAmount || 0;
    const maxAmount = maxAllInAmount || 0;
    const myCallAmount = myPlayerData?.callAmount || 0;
    const minBetSize = Math.min(addBigNumbers(minAmount, myCallAmount).toNumber(), addBigNumbers(maxAmount, myCallAmount).toNumber());

    const adjustedBet = multiplyBigNumbers(Math.floor(divideBigNumbers(betAmount, betUnit).toNumber()), betUnit).toNumber();
    const nextPossibleBet = addBigNumbers(adjustedBet, betUnit).toNumber();

    if (addBigNumbers(maxAmount, myCallAmount).toNumber() < nextPossibleBet) {
      return addBigNumbers(maxAmount, myCallAmount).toNumber();
    }

    return Math.max(minBetSize, adjustedBet);
  };

  useEffect(() => {
    if (winners?.length) {
      if (header?.action === HeaderAction.ROUND_SETTLEMENT) {
        _.delay(() => {
          winners.find(winner => winner.seatId === mySeatData?.seatId) ? setWinnerType('hero') : setWinnerType('villain');
        }, DELAY_WINNING_DISPLAY);
      } else if (header?.action === HeaderAction.USER_JOIN) {
        winners.find(winner => winner.seatId === mySeatData?.seatId) ? setWinnerType('hero') : setWinnerType('villain');
      }
    } else {
      setWinnerType(undefined);
    }
  }, [winners, header]);

  useEffect(() => {
    /**
     * 사용자 잔고 새로고침이 필요한 테이블 이벤트
     */
    channel.postMessage({ type: 'UPDATE_BALANCE' });
  }, [mySeatStatus]);

  useEffect(() => {
    /**
     * 칩 추가 요청 결과 토스트 메시지
     */
    if (!isOwner && header?.action === HeaderAction.ALLOW_ADD_CHIPS && header?.type === HeaderType.PRIVATE) {
      if (updateInfo?.isAllowed) {
        if (tableStatus === 'PLAYING') {
          showSuccessToast(i18n.t('MESSAGE.ChipWillBeAdded'));
        } else {
          showSuccessToast(i18n.t('MESSAGE.ChipApproved'));
        }
      } else {
        showFailToast(i18n.t('MESSAGE.ChipDenied'));
      }
    }
  }, [isOwner, header, tableStatus, updateInfo]);

  return {
    name,
    tableSnapshot,
    header,
    winnerType,
    handId,
    shareCode: tableSettings?.shareCode,
    isLastHandAction,
    tableSettings,
    action: header?.action,
    headerType: header?.type,
    updateInfo,
    tableId: Number(tableId),
    players,
    seats,
    handRound: round,
    mySeatData,
    needsChipGathering,
    gameType: isTournamentTable ? gameType : tableSettings?.gameType,
    mySeatId: mySeatData?.seatId,
    mySeatStatus,
    myPlayerData,
    myUserData,
    isMyTurn,
    communityCards,
    pot,
    sidePots,
    totalPot,
    totalPotForDisplay,
    amountToCall,
    handStrength,
    isOwner,
    tableCallAmount,
    tableStatus,
    bbAmount: blinds?.big,
    sbAmount: blinds?.small,
    winners,
    myAllowedActions,
    minRaiseAmount,
    reservedSitOut,
    tableUsers,
    isWaiting: tableStatus === 'WAITING' && (isOwner || mySeatData?.status === 'SEAT_IN'),
    isChatEnable: mySeatData !== undefined && mySeatData.status !== 'STAND',
    availableSeats,
    canReserve,
    maxAllInAmount,
    betUnit,
    adjustBetToBetUnit,
    adjustBetToBetUnitForRaise,
    myStack,
    chipRequests,
    isAddChipAvailable: isAddChipAvailable,
    myTableAction,
    myUserTotalStack,
    tableAssetInfo,
    chipDecimal,
    roles,
    isTieGame,
    ask,
    canStart,
    canSeatBackIn,
    canBuyIn,
    prepareStart,
    //TODO TOURNAMENT 관련 처리 중
    tournamentTableInformation,
    tournamentInformation,
    tournamentSummary,
    blindInfo,
    entrants,
    prizeInfo,
    prizePool,
    gameFormat,
    isTournamentTable,
    tournamentShareCode
  };
};

export default useTable;
