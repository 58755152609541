import React, { useEffect, useMemo } from 'react';
import { ActionType } from '../components/template/room/bettingControls/types';
import { bpSocketService } from 'src/services/BpWebSocketService';

import useDevice from 'src/hooks/useDevice';

import { PlayerAction } from 'src/store/slices/streamingTypes';
import SoundManager from '../utils/SoundUtil';
import _, { throttle } from 'lodash';
import { useTableContext } from './TableProvider';
import { useUserTableSetting } from './useUserTableSetting';
import { addBigNumbers, subtractBigNumbers } from '../utils/BigNumberUtil';
import { numberToDisplayString } from '../utils/StringUtil';
import { useTranslation } from 'react-i18next';

export const useTableHandActions = () => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const { isTournamentTable, adjustBetToBetUnit, adjustBetToBetUnitForRaise, gameType, tableId, tableCallAmount, isMyTurn, myAllowedActions, tableAssetInfo, minRaiseAmount, maxAllInAmount, handRound, amountToCall, handId, betUnit, myPlayerData, tableSnapshot, tableSettings } = useTableContext();
  const userTableSetting = useUserTableSetting();
  const [isPresetOpen, setIsPresetOpen] = React.useState(false);
  const [reservedAction, setReservedAction] = React.useState<ActionType | undefined>(undefined);
  const [prevTableCallAmount, setPrevTableCallAmount] = React.useState(tableCallAmount);
  const [bet, setBet] = React.useState(0);
  const [betForRaise, setBetForRaise] = React.useState(0);
  const [minBetForRaise, setMinBetForRaise] = React.useState(0);
  const minRaise = useMemo(() => minRaiseAmount ?? 0, [minRaiseAmount]);
  const maxRaise = useMemo(() => maxAllInAmount ?? 0, [maxAllInAmount]);

  React.useEffect(() => {
    if (isMyTurn) {
      SoundManager._instance?.playSound('my-turn');
    }
  }, [isMyTurn]);

  React.useEffect(() => {
    setReservedAction(undefined);
  }, [handRound]);

  React.useEffect(() => {
    if (tableCallAmount !== prevTableCallAmount) {
      setPrevTableCallAmount(tableCallAmount);
    }
  }, [tableCallAmount]);

  const getAllowedActions = () => {
    const actions: ActionType[] = [];

    if (myAllowedActions?.includes(PlayerAction.FOLD)) {
      actions.push('FOLD');
    }
    if (myAllowedActions?.includes(PlayerAction.CHECK)) {
      actions.push('CHECK');
    }
    if (myAllowedActions?.includes(PlayerAction.CALL)) {
      actions.push('CALL');
    }
    if (myAllowedActions?.includes(PlayerAction.BET)) {
      actions.push('BET');
    }
    if (myAllowedActions?.includes(PlayerAction.RAISE)) {
      actions.push('RAISE');
    }
    if (myAllowedActions?.includes(PlayerAction.ALLIN)) {
      actions.push('ALLIN');
    }
    return actions;
  };

  const getActionTitle = (action: ActionType, isPreAction: boolean) => {
    if (action === 'FOLD') {
      if (isPreAction) {
        return myAllowedActions?.includes(PlayerAction.CHECK) ? `${t('ACTIONS.CheckFold', { newline: '\\n' })}` : `${t('ACTIONS.Fold')}`;
      } else {
        return `${t('ACTIONS.Fold')}`;
      }
    }
    if (action === 'CHECK') {
      return `${t('ACTIONS.Check')}`;
    }
    if (action === 'CALL') {
      return `${t('ACTIONS.Call')} \\n${numberToDisplayString(amountToCall, undefined, userTableSetting?.isBlindView, tableSettings?.blindAmount!.big ?? 1) || ''}${userTableSetting?.isBlindView ? ' BB' : ''}`;
    }
    if (action === 'BET' || action === 'RAISE') {
      let msg = `${maxRaise > bet ? (action === 'RAISE' && !isPreAction ? `${t('ACTIONS.RaiseTo')}` : `${t('ACTIONS.Bet')}`) : `${t('ACTIONS.Allin')}`}\\n`;
      if (isPreAction && maxRaise <= bet) {
        msg = `${t('ACTIONS.Allin')}\\n`;
      }
      if (isMyTurn) {
        if (isPresetOpen) {
          const betAmount = adjustBetToBetUnitForRaise(betForRaise);
          msg += numberToDisplayString(betAmount, undefined, userTableSetting?.isBlindView, tableSettings?.blindAmount!.big ?? 1) + (userTableSetting?.isBlindView ? ' BB' : '');
        }
      } else {
        msg += minRaiseAmount ? numberToDisplayString(minRaiseAmount, undefined, userTableSetting?.isBlindView, tableSettings?.blindAmount!.big ?? 1) + (userTableSetting?.isBlindView ? ' BB' : '') : '';
      }
      return msg;
    }
    if (action === 'ALLIN') {
      return `${t('ACTIONS.Allin')} \\n${numberToDisplayString(addBigNumbers(maxRaise, isMyTurn ? myPlayerData?.callAmount ?? 0 : 0).toNumber(), undefined, userTableSetting?.isBlindView, tableSettings?.blindAmount!.big ?? 1) || ''}${userTableSetting?.isBlindView ? ' BB' : ''}`;
    }
    return action;
  };

  const toggleReservedAction = (action: ActionType) => {
    setReservedAction(prev => (prev === action ? undefined : action));
  };

  const onActionClick = throttle(
    (action: ActionType) => {
      switch (action) {
        case 'FOLD':
          fold();
          break;
        case 'CHECK':
          check();
          break;
        case 'CALL':
          call();
          break;
        case 'BET':
        case 'RAISE':
        case 'ALLIN':
          betting('action', action);
          break;
      }
    },
    1000,
    { trailing: false }
  );

  const doReservedAction = throttle(
    (action: ActionType) => {
      switch (action) {
        case 'FOLD':
          const isCheckAvailable = myAllowedActions?.includes(PlayerAction.CHECK);
          isCheckAvailable ? onActionClick('CHECK') : onActionClick('FOLD');
          break;
        case 'CHECK':
          check();
          break;
        case 'CALL':
          call();
          break;
        case 'BET':
        case 'RAISE':
        case 'ALLIN':
          betting('preAction', action);
          break;
      }
      _.delay(() => {
        setReservedAction(undefined); // UI가 깜빡이는 것을 방지함
      }, 500);
    },
    1000,
    { trailing: false }
  );

  React.useEffect(() => {
    if (prevTableCallAmount !== tableCallAmount && !(reservedAction === 'ALLIN' || reservedAction === 'FOLD')) {
      setReservedAction(undefined);
      return;
    }
    if (!isMyTurn || !reservedAction) {
      return;
    }
    _.delay(() => {
      doReservedAction(reservedAction); // Reserved Action에 약간의 딜레이를 둠
    }, 500);
  }, [reservedAction, isMyTurn, tableCallAmount]);

  React.useEffect(() => {
    if (isMyTurn) {
      !isMobile && setIsPresetOpen(true);
    } else {
      setIsPresetOpen(false);
    }
  }, [isMyTurn]);

  const check = () => {
    if (!gameType || !tableId) return;
    bpSocketService.gameBet(0, handId, handRound, isTournamentTable);
  };

  const call = () => {
    if (!gameType || !tableId) return;
    bpSocketService.gameBet(amountToCall, handId, handRound, isTournamentTable);
  };

  const betting = (type: 'preAction' | 'action', actionType: ActionType) => {
    if (!gameType || !tableId) return;
    if (type === 'action' && !isPresetOpen && actionType !== 'ALLIN') {
      setIsPresetOpen(true);
      return;
    }
    bpSocketService.gameBet(adjustBetToBetUnit(bet), handId, handRound, isTournamentTable);
  };

  const fold = () => {
    if (!gameType || !tableId) return;
    bpSocketService.gameFold(handId, handRound, isTournamentTable);
  };

  const maxBet = useMemo(() => {
    return myPlayerData?.maxAllInAmount || 0;
  }, [myPlayerData]);

  useEffect(() => {
    setBet(Math.min(minRaiseAmount || 0, maxBet));
    setBetForRaise(Math.min(addBigNumbers(minRaiseAmount || 0, myPlayerData?.callAmount ?? 0).toNumber(), addBigNumbers(maxBet, myPlayerData?.callAmount ?? 0).toNumber()));
    setMinBetForRaise(Math.min(addBigNumbers(minRaiseAmount || 0, myPlayerData?.callAmount ?? 0).toNumber(), addBigNumbers(maxBet, myPlayerData?.callAmount ?? 0).toNumber()));
  }, [minRaiseAmount, isMyTurn]);

  const handleBetChange = (value: number) => {
    setBetForRaise(value);
    setBet(Math.max(subtractBigNumbers(value, myPlayerData?.callAmount ?? 0).toNumber(), 0));
  };

  return {
    bet,
    betForRaise,
    minBetForRaise,
    handleBetChange,
    maxBet,
    minRaise,
    maxRaise,
    minRaiseAmount,
    maxAllInAmount,
    reservedAction,
    isPresetOpen,
    getAllowedActions,
    getActionTitle,
    onReservedActionClick: toggleReservedAction,
    onActionClick
  };
};
