import { useNavigate, useParams } from 'react-router-dom';
import i18n, { DEFAULT_LANGUAGE } from '../i18n';

/**
 * useLangNavigate: 현재 언어를 포함하거나 기본 언어는 생략된 경로로 네비게이션하는 훅
 */
const useLangNavigate = () => {
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { lang } = useParams<{ lang: string }>();

  return (pathOrDelta: string | number, options?: { replace?: boolean; state?: any }) => {
    // 숫자 타입 path는 navigate(delta)로 처리
    if (typeof pathOrDelta === 'number') {
      navigate(pathOrDelta);
      return;
    }

    const language = currentLanguage || lang || DEFAULT_LANGUAGE;

    // 기본 언어는 URL에서 제거
    const prefix = language === DEFAULT_LANGUAGE ? '' : `/${language}`;
    const formattedPath = pathOrDelta.startsWith('/') ? pathOrDelta : `/${pathOrDelta}`;

    navigate(`${prefix}${formattedPath}`, options);
  };
};

export default useLangNavigate;
