import { useParams } from 'react-router-dom';
import i18n, { DEFAULT_LANGUAGE } from '../i18n';
import useDevice from './useDevice';

/**
 * useOpenTable: 언어에 따라 경로를 처리하여 테이블 창을 여는 훅
 */
const useOpenTable = () => {
  const { lang } = useParams<{ lang: string }>();
  const currentLanguage = i18n.language;

  const openTable = (shareCode?: string, tournamentId?: string | null, options?: { width?: number; height?: number }) => {
    if (tournamentId !== null && tournamentId !== undefined && !shareCode) {
      return;
    }

    const language = lang || currentLanguage || DEFAULT_LANGUAGE;

    // 기본 언어는 URL에서 제거
    const prefix = language === DEFAULT_LANGUAGE ? '' : `/${language}`;
    const shareCodePostfix = shareCode ? `/${shareCode}` : '';

    // URL 설정: 토너먼트인지 캐시 게임인지 확인
    const url = tournamentId
      ? `${prefix}/tournament/${tournamentId}/table${shareCodePostfix}` // Tournament table URL
      : `${prefix}/cashgame/table${shareCodePostfix}`; // Cash game table URL

    // 윈도우 크기 및 위치 설정
    const width = options?.width || 1600;
    const height = options?.height || 900;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    // 팝업 윈도우 설정
    const windowName = shareCode;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;

    // 테이블 창 열기
    return window.open(url, windowName, windowFeatures);
  };

  return {
    openTable
  };
};

export default useOpenTable;
