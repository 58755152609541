import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { BlindLevel, PayoutInfo } from '../../../store/slices/tournamentTypes';
import { getTournamentDuration } from '../../../utils/DateUtil';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';

const TournamentDetailTabStructure: React.FC = () => {
  const { tournamentStructure } = useTournamentLobby();
  const { isMobile } = useDevice();

  const renderBlindRow = (blindSchedule: BlindLevel) => (
    <StyledBlindScheduleRow key={blindSchedule.blindId} selected={false}>
      <StyledCell color={'gray300'}> {blindSchedule.level}</StyledCell>
      <StyledCell color={'gray300'}>{`${blindSchedule.sb}/${blindSchedule.bb}`}</StyledCell>
      <StyledCell color={'gray300'}>{blindSchedule.ante}</StyledCell>
      <StyledCell color={'gray300'}>{getTournamentDuration(blindSchedule.interval)}</StyledCell>
    </StyledBlindScheduleRow>
  );

  const renderPayoutRow = (index: number, payoutInfo: PayoutInfo) => (
    <StyledPayoutRow key={index} selected={false}>
      <StyledCell color={'gray300'}>{`${payoutInfo?.minEntry}~${payoutInfo?.maxEntry}`}</StyledCell>
      <StyledCell color={'gray300'}>{payoutInfo.placesPaid}</StyledCell>
    </StyledPayoutRow>
  );

  return (
    <StructureContainer>
      <TableSection flex={1} maxHeight={isMobile ? 270 : 296}>
        <TitleText fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} marginBottom={isMobile ? 16 : 12} marginTop={16} textColor={'white'} text={'Blinds Schedule'} textAlign={'start'} />

        <StyledTableHeader>
          <StyledBlindScheduleHeader>
            <StyledCell color={'gray400'}>Level</StyledCell>
            <StyledCell color={'gray400'}>Blinds</StyledCell>
            <StyledCell color={'gray400'}>Ante</StyledCell>
            <StyledCell color={'gray400'}>Minute</StyledCell>
          </StyledBlindScheduleHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentStructure?.blindSchedule?.map(renderBlindRow)}</StyledTableBody>
      </TableSection>

      <TableSection flex={1} maxHeight={isMobile ? 270 : 296}>
        <TitleText fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} marginBottom={isMobile ? 16 : 12} marginTop={isMobile ? 16 : 12} textColor={'white'} text={'Payout Structure'} textAlign={'start'} />
        <StyledTableHeader>
          <StyledPayoutHeader>
            <StyledCell color={'gray400'}>Entries</StyledCell>
            <StyledCell color={'gray400'}>Places Paid</StyledCell>
          </StyledPayoutHeader>
        </StyledTableHeader>
        <StyledTableBody>
          {tournamentStructure?.payoutStructure?.info?.map((value, index, array) => {
            return renderPayoutRow(index, value);
          })}
        </StyledTableBody>
      </TableSection>
    </StructureContainer>
  );
};

const TitleText = styled(Text)`
  margin-left: 24px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;

const StructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  gap: 1px;
  background-color: ${({ theme }) => theme.colors.gray900};
`;

const StyledBlindScheduleHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 2fr 1fr 1fr;
`;

const StyledBlindScheduleRow = styled(StyledRow)`
  grid-template-columns: 1fr 2fr 1fr 1fr;
`;

const StyledPayoutHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPayoutRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

export default TournamentDetailTabStructure;
