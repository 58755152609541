import React, { useState, useLayoutEffect, useEffect } from 'react';
import { CardProps } from './types';
import { BigSuit, CardBack, CardFront, CardNum, CardPattern, LogoWrap, SmallSuit } from './style';
import useCard from './useCard';
import useAnimationControl from '../../../hooks/useAnimation';
import styled from '@emotion/styled';
import { HeaderAction } from '../../../store/slices/streamingTypes';
import { motion } from 'framer-motion';
import { LogoIcon } from '../icon';
import { useTableContext } from '../../../hooks/TableProvider';
import _ from 'lodash';

const Card = ({ cardType, card, cardIndex, patternColor = 'gray700', isFolded, ...props }: CardProps) => {
  const { bgColor, smallSuit, suit, textColor, borderColor } = useCard({ card });
  const { action } = useTableContext();
  const { dealHoleCard, dealCommunityCard, cardOpen, reset, cardControls, initial, ref } = useAnimationControl({ type: 'card' });

  const [domReady, setDomReady] = useState(false);

  // DOM이 준비된 상태 확인 (ref가 세팅된 후)
  useLayoutEffect(() => {
    if (ref.current) {
      setDomReady(true);
    }
  }, [ref]);

  // 커뮤니티 카드 배치 애니메이션
  useEffect(() => {
    if (!domReady || !card || cardType !== 'community' || cardIndex === undefined) return;
    // USER_JOIN 액션 시에는 애니메이션 없이 바로 놓습니다.
    dealCommunityCard({
      hasAnimation: action !== HeaderAction.USER_JOIN,
      cardIndex,
      isFlipped: card !== 'XX'
    });
  }, [domReady, card, cardType, action, cardIndex, dealCommunityCard]);

  // 홀 카드 배치 애니메이션
  useEffect(() => {
    if (!domReady || !card || cardType !== 'hole' || cardIndex === undefined) return;

    const shouldAnimate = action === HeaderAction.ROUND_PREFLOP || action === HeaderAction.USER_JOIN || action === HeaderAction.TOURNAMENT_CONNECT;
    if (shouldAnimate) {
      dealHoleCard({
        hasAnimation: action === HeaderAction.ROUND_PREFLOP,
        cardIndex,
        isFlipped: card !== 'XX'
      });
    } else if (card !== 'XX') {
      // 이미 오픈된 상태여야 하는 경우
      cardOpen({ cardIndex });
    }
  }, [domReady, action, card, cardType, cardIndex, dealHoleCard, cardOpen]);

  // 테이블 시작/일시정지 시 reset 호출
  useEffect(() => {
    if (!domReady) return;
    if (action === HeaderAction.TABLE_START || action === HeaderAction.TABLE_PAUSE) {
      reset();
    }
  }, [domReady, action, reset]);

  return (
    <StyledCardWrap cardType={cardType} cardIndex={cardIndex} ref={ref}>
      <StyledMotionDiv initial={initial} animate={cardControls}>
        {card && card !== 'XX' && (
          <CardFront bgColor={bgColor} borderColor={borderColor} isBestCard={props.isBestCard} disabled={props.disabled}>
            <CardNum color={textColor}>{card?.[0] === 'T' ? '10' : card?.[0]}</CardNum>
            <SmallSuit>{smallSuit}</SmallSuit>
            <BigSuit>{suit}</BigSuit>
          </CardFront>
        )}
        <CardBack>
          <CardPattern patternColor={patternColor}>
            <div />
          </CardPattern>
          <LogoWrap>
            <LogoIcon color={'gray400'} />
          </LogoWrap>
        </CardBack>
      </StyledMotionDiv>
    </StyledCardWrap>
  );
};

const StyledMotionDiv = styled(motion.div)`
  transform-style: preserve-3d;
  position: absolute;
  /* 초기 렌더링 시에도 카드를 볼 수 있도록 기본 상태를 visible로 설정 */
  visibility: visible;
  opacity: 1;
`;

const StyledCardWrap = styled.div<{ cardIndex?: number; cardType: 'community' | 'hole' }>`
  position: absolute;
  width: 5em;
  height: 3.13em;
  left: 0;

  @media (max-width: 768px) {
    width: 2.75em;
    height: 3.88em;
  }
`;

export default React.memo(Card, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
