import styled from '@emotion/styled';
import Text from 'src/components/common/text/Text';
import React, { useEffect, useRef, useState } from 'react';
import { numberToDisplayString, timeFormat } from '../../../../utils/StringUtil';
import useDevice from '../../../../hooks/useDevice';
import { useTableContext } from '../../../../hooks/TableProvider';
import { TableAssetInfo } from '../../../../store/slices/streamingTypes';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { dismissModal, ModalType, showModal } from '../../../../store/slices/modalSlice';
import { useTranslation } from 'react-i18next';
import useRouteMatch from '../../../../hooks/useRouteMatch';

type SecondContent = {
  title: 'Avg. Stack' | 'Min. Stack' | 'Late Reg';
  value: string;
};

const TableInfo = () => {
  const [isTournamentInfoOpen, setIsTournamentInfoOpen] = useState(false);
  const { t } = useTranslation();
  const { isCashGame } = useRouteMatch();
  const interval = useRef<NodeJS.Timeout>();
  const [stateDiffTime, setStateDiffTime] = useState<number>(0);
  const { handId, tableSettings, prizePool, tournamentSummary, prizeInfo, entrants, blindInfo } = useTableContext();
  const dispatch = useDispatch();
  const closeModal = () => dispatch(dismissModal());
  const { isMobile } = useDevice();
  const [secondContent, setSecondContent] = useState<SecondContent>();
  const getChipTitle = (assetInfo: TableAssetInfo) => {
    switch (assetInfo.name) {
      case 'BPP':
        return t('BUTTON.PlayMoney');
      default:
        return assetInfo.name;
    }
  };

  useEffect(() => {
    if (blindInfo?.current.endAt) {
      const stateEndTime = new Date(blindInfo?.current.endAt);
      const end = stateEndTime;
      interval.current = setInterval(() => {
        stateEndTime.getTime();
        const now = new Date().getTime();
        const diff = end.getTime() - now;

        setStateDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setStateDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setStateDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [blindInfo]);

  useEffect(() => {
    if (tournamentSummary?.myRank === tournamentSummary?.prizeInfo?.placesPaid) {
      setSecondContent({ title: 'Min. Stack', value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.smallest, 1) + ' BB' });
    } else {
      setSecondContent({ title: 'Avg. Stack', value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.average, 1) + ' BB' });
    }
    // TODO:late reg 가 가능할때 로직 추가
  }, [tournamentSummary]);

  useEffect(() => {
    if (!isMobile) {
      closeModal();
    }
  }, [isMobile]);

  return (
    <TableInfoWrap isInfoOpen={isTournamentInfoOpen}>
      {(isCashGame || isMobile) &&
        (isCashGame ? (
          <TextWrap>
            {tableSettings?.asset && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`${t('TABLE.Chips')}: ${getChipTitle(tableSettings?.asset)}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
            <Text
              fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
              textAlign={isMobile ? 'center' : 'start'}
              text={`${t('TABLE.Blinds')}: ${numberToDisplayString(tableSettings?.blindAmount?.small)} / ${numberToDisplayString(tableSettings?.blindAmount?.big)}`}
              textColor={isMobile ? 'white_40' : 'gray300'}
              marginTop={0}
            />
            {handId && handId > 0 && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`${t('TABLE.Hand')}: #${handId}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
            {tableSettings?.tablePassword && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`${t('CASHGAME.Password')}: ${tableSettings?.tablePassword}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
          </TextWrap>
        ) : (
          <TextWrap
            onClick={() => {
              dispatch(showModal(ModalType.TournamentInfoModal));
            }}
          >
            <Text
              fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
              textAlign={isMobile ? 'center' : 'start'}
              text={blindInfo?.current.type === 'BREAK' ? 'Break Time' : `Level ${blindInfo?.current.level}: ${timeFormat(stateDiffTime)}`}
              textColor={isMobile ? 'white_40' : 'gray300'}
              marginTop={0}
            />
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`${secondContent?.title}: ${secondContent?.value}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Prize: ${numberToDisplayString(prizeInfo?.total, 0)} ${prizePool?.asset?.name || ''}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />
            {(tournamentSummary?.myRank || 0) > 0 && (
              <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`My Rank: ${tournamentSummary?.myRank || 0}/${entrants?.length || 0}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />
            )}
          </TextWrap>
        ))}
    </TableInfoWrap>
  );
};

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 10;
  user-select: none;
`;

const TableInfoWrap = styled.div<{ isInfoOpen?: boolean }>`
  position: absolute;
  top: 76px;
  gap: 8px;
  left: 24px;
  display: flex;
  flex-direction: column;
  z-index: auto;
  user-select: none;

  @media (max-width: 768px) {
    position: absolute;
    top: 55%;
    left: 50%;
    display: flex;
    gap: 0;
    transform: translateX(-50%);
    align-self: center;
    align-items: center;
    z-index: 100;
    ${({ isInfoOpen }) =>
      isInfoOpen &&
      css`
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-self: initial;
        transform: translateX(0);
      `}
  }
`;

export default TableInfo;
