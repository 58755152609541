import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as AlertIcon } from 'src/assets/img/alert.svg';

import HorizontalLayout from '../../layout/HorizontalLayout';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../common/text/Text';
import theme from '../../../styles/theme';
import { dismissModal, ModalStatus, ModalType, setModalResponse } from '../../../store/slices/modalSlice';
import { useLogoutMutation } from '../../../store/api/auth';
import useTableActions from 'src/hooks/useTableActions';
import { persistor, RootState } from '../../../store/store';
import { bpSocketService } from '../../../services/BpWebSocketService';
import useLangNavigate from '../../../hooks/useLangNavigate';
import { useTranslation } from 'react-i18next';
import { useMyBalance } from '../../../hooks/useMyBalance';

interface AlertModalProps {
  modalType: ModalType;
}

const AlertModal: React.FC<AlertModalProps> = ({ modalType }) => {
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [requestLogout] = useLogoutMutation();
  const { refetchBalance } = useMyBalance();
  const { modalRequest } = useSelector((state: RootState) => state.modalStateSlice);

  const { stand } = useTableActions();

  let title = '';
  let content = '';
  let confirmButtonText = 'Confirm';
  switch (modalType) {
    case ModalType.TournamentUnregisterModal:
      title = 'Unregister';
      content = 'Are you sure you want to unregister from the tournament?';
      confirmButtonText = 'Unregister';
      break;
    case ModalType.LogoutModal:
      title = t('LOGOUT.LogOut');
      content = t('LOGOUT.LogOutMessage');
      confirmButtonText = t('LOGOUT.LogOut');
      break;
    case ModalType.StandModal:
      title = t('MODAL.StandTitle');
      content = t('MODAL.StandDes');
      confirmButtonText = t('ACTIONS.Stand');
      break;
    case ModalType.ClosedByOwnerModal:
      title = t('MODAL.ClosedTitle');
      content = t('MODAL.ClosedDes');
      confirmButtonText = t('BUTTON.Okay');
      break;
    case ModalType.ClosedByServerModal:
      title = t('MODAL.ClosedTitle');
      content = t('MODAL.ClosedDes');
      confirmButtonText = t('BUTTON.Okay');
      break;
    case ModalType.RestrictedModal:
      title = t('MODAL.IpRestrictedTitle');
      content = t('MODAL.IpRestrictedDes');
      confirmButtonText = t('BUTTON.Okay');
      break;
    default:
      title = 'Um..Something wrong';
      content = 'Unexpected Error occurred';
      confirmButtonText = 'Continue';
      break;
  }

  const onConfirm = () => {
    switch (modalType) {
      case ModalType.LogoutModal:
        requestLogout().finally(() => {
          persistor.purge().then();
          if (window.location.pathname.includes('settings')) {
            navigate('/');
          }
        });
        break;
      case ModalType.TournamentUnregisterModal:
        if (modalRequest?.data?.tournamentId) {
          bpSocketService.unregisterForTournament(modalRequest?.data?.tournamentId);
        }
        break;
      case ModalType.StandModal:
        stand();
        break;
      case ModalType.CloseModal:
        break;
      case ModalType.ClosedByOwnerModal:
        navigate('/');
        break;
      case ModalType.LeaveModal:
        navigate('/');
        break;
      default:
        break;
    }
    dispatch(setModalResponse({ type: modalType, status: ModalStatus.Approve, data: modalRequest?.data }));
    dispatch(dismissModal());
  };

  const onCancel = () => {
    dispatch(setModalResponse({ type: modalType, status: ModalStatus.Cancel, data: modalRequest?.data }));
    dispatch(dismissModal());
  };

  return (
    <Background>
      <AlertIcon />
      <Text fontStyle={'TextXl_Semibold'} textAlign={'start'} text={title} />
      <Text fontStyle={'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={content} />
      <HorizontalLayout margin={'32px 0 0 0'} gap={12}>
        {modalType !== ModalType.ClosedByOwnerModal && <CancelButton onClick={onCancel}>{t('BUTTON.Cancel')}</CancelButton>}
        <ConfirmButton onClick={onConfirm}>{confirmButtonText}</ConfirmButton>
      </HorizontalLayout>
    </Background>
  );
};

export default AlertModal;

const Background = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 40px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 80vw;
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  min-height: 48px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.error600};
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.error500};
  }
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  box-shadow: 0 0 0 1px ${theme.colors.gray200};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray600};
  }
`;
