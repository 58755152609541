import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';

type BodyContainerProps = {
  children: React.ReactNode;
};

const PolicyLayout: React.FC<BodyContainerProps> = ({ children }) => {
  const { isMobile } = useDevice();
  return (
    <Background>
      <Content>{children}</Content>
    </Background>
  );
};

const Background = styled.section`
  flex-direction: column;
  padding-top: 112px;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    padding-top: 4px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const Content = styled.div`
  width: 700px;
  max-width: 728px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
    display: flex;
  }
`;

export default PolicyLayout;
