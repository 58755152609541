import { useRef } from 'react';
import { AnimationDefinition, useAnimation } from 'framer-motion';
import useCalculateElements from './useCalculateElements';
import useDevice from './useDevice';
import { DURATION_BET_MOVE, DURATION_CARD_DEAL, DURATION_CARD_FLIP } from '../utils/AnimationUtil';
import { logError } from '../utils/ConsoleUtil';

interface DealCardProps {
  hasAnimation: boolean;
  cardIndex?: number;
  isFlipped?: boolean;
}

interface ChipPosition {
  left?: string;
  top?: string;
}

interface useAnimationControlProps {
  fontSize?: number;
  type: 'card' | 'win' | 'bet' | 'pot';
}

const useAnimationControl = ({ fontSize }: useAnimationControlProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // 카드 애니메이션용 컨트롤 및 상태
  const cardControls = useAnimation();
  const cardIsAnimating = useRef(false);
  const cardAnimatedState = useRef<any>();

  // 칩 애니메이션용 컨트롤 및 상태
  const chipControls = useAnimation();
  const chipIsAnimating = useRef(false);
  const chipAnimatedState = useRef<any>();

  const initialState = useRef<AnimationDefinition>({
    visibility: 'hidden',
    opacity: 0
  });

  const { isMobile } = useDevice();

  // 공통: 카드 애니메이션 종료 후 상태 갱신 함수
  const finalizeCardAnimation = (state: AnimationDefinition) => {
    cardControls.set(state);
    cardAnimatedState.current = state;
    cardIsAnimating.current = false;
  };

  // 공통: 칩 애니메이션 종료 후 상태 갱신 함수
  const finalizeChipAnimation = (state: AnimationDefinition) => {
    chipControls.set(state);
    chipAnimatedState.current = state;
    chipIsAnimating.current = false;
  };

  // 카드 관련 애니메이션
  const dealCommunityCard = async ({ cardIndex, isFlipped, hasAnimation }: DealCardProps) => {
    if (!ref.current || cardIsAnimating.current || cardAnimatedState.current || cardIndex === undefined) return;
    const position = useCalculateElements({ ref, isMobile }).calculate();
    const isFlop = [0, 1, 2].includes(cardIndex);

    const destinationLeft = (isMobile ? 5 : 5.1) * cardIndex + 'em';
    const animatedStateData: AnimationDefinition = {
      left: destinationLeft,
      rotateY: 180,
      top: '0em',
      opacity: 1
    };

    cardControls.set({ visibility: 'visible', opacity: 0 });

    try {
      if (hasAnimation) {
        cardIsAnimating.current = true;
        // 초기 상태 설정
        cardControls.set({ rotateY: 0 });
        // 첫 애니메이션: 카드 자리로 이동
        await cardControls.start({
          left: [position.left, isFlop ? '0em' : destinationLeft],
          top: [position.top, '0em'],
          opacity: [0, 1],
          rotateY: 0,
          transition: { duration: 0.2 }
        });

        // 플랍 전이면 카드를 뒤집는 애니메이션
        if (isFlipped) {
          await cardControls.start({
            rotateY: [0, 180],
            transition: { duration: 0.2, delay: 0.1 }
          });
        }

        // 플랍카드(0,1,2)일 경우 위치를 조정하며 최종 상태로 이동
        if (isFlop) {
          await cardControls.start({
            rotateY: 180,
            left: ['0em', destinationLeft],
            top: ['0em', '0em'],
            transition: { duration: 0.2 }
          });
        }
      }
    } catch (e) {
      logError(e);
    } finally {
      finalizeCardAnimation(animatedStateData);
    }
  };

  // 홀 카드 나누기
  const dealHoleCard = async ({ cardIndex, isFlipped, hasAnimation }: DealCardProps) => {
    if (!ref.current || cardIsAnimating.current || cardIndex === undefined || cardAnimatedState.current) return;
    const position = useCalculateElements({ ref, isMobile }).calculate();

    const finalLeft = cardIndex === 0 ? '0.5em' : '3.4em';
    const finalRotateY = isFlipped ? '180deg' : '0deg';
    const finalRotate = isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : undefined;

    const animatedStateData: AnimationDefinition = {
      rotate: finalRotate,
      left: finalLeft,
      top: '0em',
      rotateY: finalRotateY,
      opacity: 1
    };

    cardControls.set({ visibility: 'visible', opacity: 0 });

    try {
      if (hasAnimation) {
        cardIsAnimating.current = true;
        cardControls.set({ rotateY: 0, rotate: isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : 0 });

        // 이동 애니메이션
        await cardControls.start({
          left: [position.left, finalLeft],
          top: [position.top, '0em'],
          opacity: [0, 1],
          rotateY: 0,
          rotate: isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : 0,
          transition: { duration: DURATION_CARD_DEAL }
        });

        // 카드 뒤집기
        if (isFlipped) {
          await cardControls.start({
            rotate: isFlipped ? ['0deg', finalRotate] : undefined,
            rotateY: [0, 180],
            transition: { duration: DURATION_CARD_FLIP }
          });
        }
      }
    } catch (e) {
      logError(e);
    } finally {
      finalizeCardAnimation(animatedStateData);
    }
  };

  // 홀 카드 뒤집기(오픈)
  const cardOpen = async ({ cardIndex }: { cardIndex?: number }) => {
    if (!ref.current || cardIsAnimating.current) return;
    if (cardAnimatedState.current?.rotateY === '180deg') return;

    const finalRotateY = '180deg';
    const finalRotate = cardIndex === 0 ? '-10deg' : '10deg';
    const animatedStateData = {
      ...cardAnimatedState.current,
      rotateY: finalRotateY,
      rotate: finalRotate
    };

    try {
      cardIsAnimating.current = true;
      await cardControls.start({
        rotateY: [0, 180],
        rotate: ['0deg', finalRotate],
        transition: { duration: DURATION_CARD_FLIP }
      });
    } catch (e) {
      logError(e);
    } finally {
      finalizeCardAnimation(animatedStateData);
    }
  };

  // 카드 강조 애니메이션
  const highlightCard = async () => {
    if (!ref.current || cardIsAnimating.current || !cardAnimatedState.current) return;

    const finalTop = '-0.8em';
    const animatedStateData = {
      ...cardAnimatedState.current,
      top: finalTop
    };

    try {
      cardIsAnimating.current = true;
      await cardControls.start({
        top: ['0em', finalTop],
        transition: { duration: DURATION_CARD_FLIP }
      });
    } catch (e) {
      logError(e);
    } finally {
      finalizeCardAnimation(animatedStateData);
    }
  };

  // 칩 베팅 애니메이션
  const betChips = async ({ hasAnimation, chipPosition }: { hasAnimation: boolean; chipPosition?: ChipPosition }) => {
    if (!ref.current || chipIsAnimating.current || !chipPosition) return;
    chipControls.set({ visibility: 'visible', opacity: 0 });

    const finalLeft = chipPosition.left ?? '0em';
    const finalTop = chipPosition.top ?? '0em';
    const animatedStateData = { left: finalLeft, top: finalTop, opacity: 1 };

    try {
      if (!chipAnimatedState.current && hasAnimation) {
        chipIsAnimating.current = true;
        await chipControls.start({
          opacity: [0, 1],
          left: ['0em', finalLeft],
          top: ['0em', finalTop],
          transition: { duration: DURATION_BET_MOVE }
        });
      }
    } catch (e) {
      logError(e);
    } finally {
      finalizeChipAnimation(animatedStateData);
    }
  };

  // 칩 모으기 애니메이션
  const gatherChips = async () => {
    if (!ref.current || chipIsAnimating.current || !chipAnimatedState.current) return;

    const position = useCalculateElements({ ref, dest: 'chip', baseFontSize: fontSize, isMobile }).calculate();
    const finalLeft = position.left;
    const finalTop = position.top;
    const animatedStateData = { opacity: 1, left: finalLeft, top: finalTop };

    try {
      chipIsAnimating.current = true;
      await chipControls.start({
        opacity: 1,
        left: [chipAnimatedState.current.left, finalLeft],
        top: [chipAnimatedState.current.top, finalTop],
        transition: { duration: DURATION_BET_MOVE }
      });
    } catch (e) {
      logError(e);
    } finally {
      finalizeChipAnimation(animatedStateData);
    }
  };

  // 승리 칩 수거
  const getWinningChips = async ({ chipPosition }: { chipPosition?: ChipPosition }) => {
    if (!ref.current || chipIsAnimating.current || !chipPosition || chipAnimatedState.current) return;

    const position = useCalculateElements({ ref, dest: 'chip', baseFontSize: fontSize, isMobile }).calculate();
    const finalLeft = chipPosition.left ?? '0em';
    const finalTop = chipPosition.top ?? '0em';
    const animatedStateData = { opacity: 1, left: finalLeft, top: finalTop };

    chipControls.set({ visibility: 'visible', opacity: 0 });

    try {
      chipIsAnimating.current = true;
      await chipControls.start({
        opacity: 1,
        left: [position.left, finalLeft],
        top: [position.top, finalTop],
        transition: { duration: DURATION_BET_MOVE }
      });
    } catch (e) {
      logError(e);
    } finally {
      finalizeChipAnimation(animatedStateData);
    }
  };

  const reset = () => {
    if (!ref.current || cardIsAnimating.current || chipIsAnimating.current) return;

    // 카드 컨트롤 초기화
    cardControls.stop();
    cardControls.set({ opacity: 0, visibility: 'hidden' });
    cardAnimatedState.current = undefined;
    cardControls.set(initialState.current);
    cardIsAnimating.current = false;

    // 칩 컨트롤 초기화
    chipControls.stop();
    chipControls.set({ opacity: 0, visibility: 'hidden' });
    chipAnimatedState.current = undefined;
    chipControls.set(initialState.current);
    chipIsAnimating.current = false;
  };

  return {
    dealHoleCard,
    dealCommunityCard,
    cardOpen,
    betChips,
    reset,
    getWinningChips,
    highlightCard,
    gatherChips,
    cardControls,
    chipControls,
    ref,
    initial: cardAnimatedState.current || chipAnimatedState.current || initialState.current
  };
};

export default useAnimationControl;
