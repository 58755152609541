import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import styled from '@emotion/styled';
import { ChipIcon } from '../icon';
import useChip from './useChip';
import { ChipProps } from './types';
import { Amount, ChipStack, StackWrap } from './style';
import useAnimationControl from '../../../hooks/useAnimation';
import { HeaderAction } from '../../../store/slices/streamingTypes';
import { DELAY_BEFORE_BET, DELAY_GATHER_CHIP, DELAY_ROUND, DELAY_WINNING_CHIP } from '../../../utils/AnimationUtil';
import { useTableContext } from '../../../hooks/TableProvider';

const Chip = ({ stack, maxStack = 3, chipGap = 2.5, fontSize = 16, chipValueMultiplier, blindAmount, position, type }: ChipProps) => {
  const { distributeChips, rounded, pos, calculatePosition } = useChip({ stack, maxStack, chipValueMultiplier, blindAmount, position });
  const { reset, betChips, gatherChips, getWinningChips, ref, initial, chipControls } = useAnimationControl({ fontSize, type: type ?? 'bet' });
  const { isLastHandAction, action } = useTableContext();

  const [domReady, setDomReady] = useState(false);

  // ref가 유효해진 시점에 domReady를 true로
  useLayoutEffect(() => {
    if (ref.current) {
      setDomReady(true);
    }
  }, [ref]);

  // pos 변경 시칸마다 미리 위치 계산
  const computedPosition = useMemo(() => {
    return pos !== undefined ? calculatePosition(pos) : undefined;
  }, [pos, calculatePosition]);

  // BET 타입: 스택이 있을 경우 베팅 애니메이션
  useEffect(() => {
    if (!domReady) return;
    if (type !== 'bet') return;
    if (stack <= 0) return;

    _.delay(async () => {
      if (computedPosition) {
        await betChips({ hasAnimation: true, chipPosition: computedPosition });
      }
    }, DELAY_BEFORE_BET);
  }, [domReady, type, stack, computedPosition, betChips]);

  // POT 타입: 스택이 있을 경우 단순 위치 지정
  useEffect(() => {
    if (!domReady) return;
    if (type !== 'pot') return;
    if (stack <= 0) return;

    _.delay(async () => {
      await betChips({ hasAnimation: false, chipPosition: { left: '0em', top: '0em' } });
    }, DELAY_BEFORE_BET);
  }, [domReady, type, stack, betChips]);

  // BET 타입 & 마지막 핸드 액션 시 칩 모으기 후 리셋
  useEffect(() => {
    if (!domReady) return;
    if (type !== 'bet') return;
    if (!isLastHandAction) return;

    _.delay(async () => {
      await gatherChips();
      _.delay(() => {
        reset();
      }, DELAY_ROUND);
    }, DELAY_GATHER_CHIP);
  }, [domReady, type, isLastHandAction, gatherChips, reset]);

  // BET 타입 & stack 존재 시 (추가 업데이트 상황, 필요 시 로직 조정)
  useEffect(() => {
    if (!domReady) return;
    if (type !== 'bet') return;
    if (stack <= 0) return;

    _.delay(async () => {
      await betChips({ hasAnimation: false, chipPosition: computedPosition });
    }, DELAY_BEFORE_BET);
  }, [domReady, type, stack, computedPosition, betChips]);

  // WIN 타입: settlement 시 칩 가져가기, TABLE_START 시 reset
  useEffect(() => {
    if (!domReady) return;
    if (type !== 'win') return;

    if (action === HeaderAction.ROUND_SETTLEMENT && stack > 0) {
      _.delay(async () => {
        await getWinningChips({ chipPosition: computedPosition });
      }, DELAY_WINNING_CHIP);
    } else if (action === HeaderAction.TABLE_START) {
      reset();
    }
  }, [domReady, type, action, stack, computedPosition, getWinningChips, reset]);

  return (
    <StyledChip ref={ref} chipType={type ?? 'bet'}>
      <StyledMotionDiv animate={chipControls} initial={initial} chipType={type ?? 'bet'}>
        <StackWrap>
          {distributeChips().map((stackGroup, index: number) => {
            if (stackGroup.length === 0) return null;
            const totalChips = stackGroup.reduce((acc, cur) => acc + cur.numChips, 0);
            return (
              <ChipStack key={index} chipGap={chipGap} maxStack={totalChips}>
                {stackGroup
                  .slice()
                  .reverse()
                  .map((chip, idx: number) => Array.from({ length: chip.numChips }).map((_, i) => <ChipIcon key={`chip-${idx}-${i}`} w={23} h={24} chipColor={chip.chipColor} />))}
              </ChipStack>
            );
          })}
        </StackWrap>
        {stack > 0 && <Amount type={type}>{rounded}</Amount>}
      </StyledMotionDiv>
    </StyledChip>
  );
};

const StyledChip = styled.div<{ chipType: 'pot' | 'bet' | 'win' }>`
  position: ${({ chipType }) => (chipType !== 'pot' ? 'absolute' : 'static')};
  width: 5em;
  height: 3.13em;
  @media (max-width: 768px) {
    width: 42.5px;
  }
`;

const StyledMotionDiv = styled(motion.div)<{ chipType: 'pot' | 'bet' | 'win' }>`
  transform-style: preserve-3d;
  position: ${({ chipType }) => (chipType === 'pot' ? 'static' : 'absolute')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 5em;
  height: 3.13em;

  @media (max-width: 768px) {
    font-size: 8.5px;
  }
`;

// React.memo를 이용한 최적화. lodash를 이용한 깊은 비교.
export default React.memo(Chip, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
