import React, { useEffect, useRef, useState } from 'react';
import { BlogIcon, CardsIcon, CheckCircleIcon, ChevronDownIcon, GiftsIcon, GlobeIcon, HelpCenterIcon, LiveSupportIcon, TournamentIcon } from '../../common/icon';
import BaseButton from '../../common/button/BaseButton';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import useLangNavigate from '../../../hooks/useLangNavigate';

import { ReactComponent as MasterCardIcon } from 'src/assets/img/mastercard.svg';
import { ReactComponent as VisaCardIcon } from 'src/assets/img/visacard.svg';
import { ReactComponent as ApplePayIcon } from 'src/assets/img/applepay.svg';
import { ReactComponent as GooglePayIcon } from 'src/assets/img/googlepay.svg';
import useDevice from '../../../hooks/useDevice';
import i18n, { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../../i18n';
import { logError, logWarn } from '../../../utils/ConsoleUtil';
import { useTranslation } from 'react-i18next';
import { show } from '@intercom/messenger-js-sdk';

interface SideNavigationProps {
  isSidebarActive?: boolean;
  path?: string;
  onClose?: () => void;
}

const languageList = [
  { id: 'en', label: 'English' },
  { id: 'de', label: 'Deutsch' },
  { id: 'es', label: 'Español' },
  { id: 'fr', label: 'Français' },
  { id: 'id', label: 'Bahasa Indonesia' },
  { id: 'ja', label: '日本語' },
  { id: 'nl', label: 'Nederlands' },
  { id: 'pt', label: 'Português' },
  { id: 'ru', label: 'Pусский' },
  { id: 'vi', label: 'Tiếng Việt' },
  { id: 'zh', label: '中文' },
  { id: 'zh-tw', label: '繁體中文' }
];

const SideNavigation = ({ isSidebarActive, path, onClose }: SideNavigationProps) => {
  const { isMobile } = useDevice();
  const [isLanguageOpen, setIsLanguageOpen] = useState<boolean>();
  const { t } = useTranslation();
  const navigate = useLangNavigate();

  const goLobby = () => {
    navigate('/cashgame');
    isMobile && onClose?.();
  };
  const goTournament = () => {
    navigate('/tournament');
    isMobile && onClose?.();
  };

  const goHelpCenter = () => {
    window.open(`https://support.basepoker.com/`, '_blank');
  };

  const goLiveSupport = () => {
    show();
  };

  const goPromotions = () => {};

  const goBlog = () => {
    window.open(`https://medium.com/@basepoker`, '_blank');
  };

  const buyCrypto = () => {
    window.open('https://buy.bitcoin.com/', '_blank');
  };

  useEffect(() => {
    if (isMobile && isSidebarActive) {
      document.body.style.overflow = 'hidden'; // 스크롤 비활성화
    } else {
      document.body.style.overflow = ''; // 원래 상태로 복구
    }

    return () => {
      document.body.style.overflow = ''; // 컴포넌트 언마운트 시 복구
    };
  }, [isSidebarActive, isMobile]);

  const changeLanguage = (newLang: string): void => {
    const isSupportedLanguage = (language: string): boolean => SUPPORTED_LANGUAGES.includes(language);
    if (newLang === i18n.language) return;
    const targetLanguage = isSupportedLanguage(newLang) ? newLang : DEFAULT_LANGUAGE;
    i18n
      .changeLanguage(targetLanguage)
      .then(() => {
        i18n.loadLanguages(targetLanguage).then();
      })
      .catch(error => {
        logError('Failed to change language:', error);
      });
  };

  useEffect(() => {
    if (!isSidebarActive) {
      setIsLanguageOpen(undefined);
    }
  }, [isSidebarActive]);

  return (
    <StyledSideBar isActive={isSidebarActive}>
      <StyledSidebarHeader>
        <StyledLinks direction={'column'} gap={24}>
          <StyledLink className={'link'} onClick={goLobby} isActive={path === 'cashgame'}>
            <CardsIcon color={path === 'cashgame' ? 'white' : 'gray400'} />
            <StyledText>{t('HEADER.CashGame')}</StyledText>
          </StyledLink>
          {/*<StyledLink className={'link'} onClick={goTournament} isActive={path === 'tournament'}>*/}
          {/*  <TournamentIcon color={path === 'tournament' ? 'white' : 'gray400'} />*/}
          {/*  <StyledText>{t('LANDING.Tournaments')}</StyledText>*/}
          {/*</StyledLink>*/}
        </StyledLinks>
        <StyledLinks direction={'column'} gap={isMobile ? 24 : 20}>
          <StyledLink className={'link'} onClick={goPromotions} isActive={path === '1'}>
            <GiftsIcon color={path === '1' ? 'white' : 'gray400'} />
            <StyledText>{t('SIDEMENU.Promotion')}</StyledText>
          </StyledLink>
          <StyledLink className={'link'} onClick={goBlog} isActive={path === '2'}>
            <BlogIcon color={path === '2' ? 'white' : 'gray400'} />
            <StyledText>{t('SIDEMENU.Blog')}</StyledText>
          </StyledLink>
          <StyledLink className={'link'} onClick={goHelpCenter} isActive={path === '2'}>
            <HelpCenterIcon color={path === '2' ? 'white' : 'gray400'} />
            <StyledText>{t('SIDEMENU.HelpCenter')}</StyledText>
          </StyledLink>
          <StyledLink className={'link'} onClick={goLiveSupport} isActive={path === '2'}>
            <LiveSupportIcon color={path === '2' ? 'white' : 'gray400'} />
            <StyledText>{t('SIDEMENU.LiveSupport')}</StyledText>
          </StyledLink>
          <StyledLink className={'link'} onClick={() => setIsLanguageOpen(prev => !prev)} isActive={isLanguageOpen}>
            <GlobeIcon color={isLanguageOpen ? 'white' : 'gray400'} />
            <StyledText>{languageList.find(language => (i18n.language ? language.id === i18n.language : language.id === 'en'))?.label}</StyledText>
            <StyledChevronIcon isSelected={isLanguageOpen}>
              <ChevronDownIcon color={isLanguageOpen ? 'white' : 'gray400'} />
            </StyledChevronIcon>
          </StyledLink>
          <StyledLanguagePanel isLanguageOpen={isLanguageOpen}>
            {languageList.map(language => (
              <StyledLanguageItem key={language.id} isSelected={language.id === i18n.language} onClick={() => changeLanguage(language.id)}>
                <div>{language.label}</div>
                {language.id === i18n.language && <CheckCircleIcon />}
              </StyledLanguageItem>
            ))}
          </StyledLanguagePanel>
        </StyledLinks>
      </StyledSidebarHeader>
      <StyledBuyCrypto>
        <BaseButton size={isMobile ? 'btn40' : 'btn44'} borderRadius={8} text={t('SIDEMENU.BuyCrypto')} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} color={'gray'} onClick={buyCrypto} />
        <StyledCards>
          <MasterCardIcon />
          <VisaCardIcon />
          <ApplePayIcon />
          <GooglePayIcon />
        </StyledCards>
      </StyledBuyCrypto>
    </StyledSideBar>
  );
};

const StyledText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;

const StyledLinks = styled.div<{ direction: 'row' | 'column'; gap: number }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => `${gap}px`};
  margin-left: ${({ direction }) => (direction === 'row' ? '60px' : '0')};
  margin-bottom: ${({ direction }) => (direction === 'row' ? '0' : '60px')};

  .link {
    gap: ${({ direction }) => (direction === 'column' ? '16px' : '8px')};
  }
`;
const StyledLink = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};

  &:hover {
    filter: brightness(1.3);
  }

  transition: filter 0.2s;
`;

const StyledLanguageItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.gray300)};
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  cursor: pointer;
  min-height: 32px;
  padding: 0 10px;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  box-sizing: border-box;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;

const growDown = keyframes`
    0% {
        max-height: 0;
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        max-height: 540px; /* 최대 높이를 조정 */
        opacity: 1;
        transform: translateY(0);
    }
`;

const shrinkUp = keyframes`
    0% {
        max-height: 540px;
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        max-height: 0;
        opacity: 0;
        transform: translateY(-10px);
    }
`;

const StyledLanguagePanel = styled.div<{ isLanguageOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  opacity: 0;
  max-height: 0;

  user-select: none; /* 텍스트 선택 방지 */
  padding: 12px;
  overflow: auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray750};

  animation: ${({ isLanguageOpen }) =>
    isLanguageOpen !== undefined &&
    (isLanguageOpen
      ? css`
          ${growDown} 0.3s ease-out forwards;
        `
      : css`
          ${shrinkUp} 0.3s ease-in forwards;
        `)};
`;

const StyledChevronIcon = styled.div<{ isSelected?: boolean }>`
  display: inline-flex;
  transition: transform 0.1s ease-in-out;
  margin-left: auto;

  &:hover {
    filter: brightness(1.3);
  }

  transform: ${({ isSelected }) => (isSelected ? 'rotate(180deg)' : 'rotate(270deg)')};
`;

const StyledCards = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledBuyCrypto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const moveLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-400px)
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
`;
const moveRight = keyframes`
    0% {
        opacity: 1;
        transform: translateX(0px)
    }
    100% {
        opacity: 0;
        transform: translateX(-400px)
    }
`;

const StyledSidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  box-sizing: border-box;
`;

const StyledSideBar = styled.div<{ isActive?: boolean }>`
    position: fixed;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 290px;
    z-index: 41;
    left: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray800};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    padding-top: 112px;
    box-sizing: border-box;
    transform: translateX(-400px);
    overflow: scroll;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.15);

    @media (min-width: 769px) {
        ${({ isActive }) =>
          isActive === true
            ? css`
                //opacity: 1;
                animation: ${moveLeft} 0.25s forwards ease-out;
              `
            : isActive === false &&
              css`
                animation: ${moveRight} 0.25s forwards ease-out;
              `};
    }
    @media (max-width: 768px) {
        padding-top: 94px;
        display: none;
        bottom: 0;

        transform: translateX(0px);
        top: 0;
        height: auto;
        justify-content: space-between;
        width: 100vw;
        ${({ isActive }) =>
          isActive === true
            ? css`
                opacity: 1;
                display: flex;
              `
            : isActive === false &&
              css`
                opacity: 0;
                display: none;
              `};
    }
}
`;

export default SideNavigation;
