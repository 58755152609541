import { EventHeader, EventName } from './streamingTypes';

export interface TournamentLobbyPacket {
  name: EventName;
  header: EventHeader;
  payload: TournamentPayload;
}

export interface TournamentPayload {
  snapshot: TournamentSnapshot;
  updates?: TournamentSnapshot;
}

// Snapshot containing tournament details and dynamic data
export interface TournamentSnapshot {
  inTheMoney: TournamentPlayer[];
  information: TournamentInformation;
  players: TournamentPlayer[];
  prizePool: PrizePool;
  structure: TournamentStructure;
  summary: TournamentSummary;
  tables: TournamentTables[];
}

// Static and dynamic tournament information
interface TournamentInformation {
  dynamic: DynamicTournamentInfo;
  static: StaticTournamentInfo;
}

interface StaticTournamentInfo {
  assetName: string;
  breakTimeDuration: number; // in minutes
  buyIn: number;
  estimatedDuration: number; // in minutes
  fee: number;
  gameType: string; // 'NL HOLD'EM'
  guarantee: number;
  id: string;
  lateRegistration: number; // in minutes
  lateRegistrationLevel: number;
  payout: TournamentPayoutInfo;
  reEntries: BaseTournamentConfig;
  startingChips: number;
  startingStack: number;
  startingTime: string; // ISO format date
  title: string;
}

interface DynamicTournamentInfo {
  currentBlindId: number;
  currentBlindStartedAt: string; // 2024-11-15T08:16:55.000Z
  entrantCount: number;
  overlay: number;
  status: TournamentStatus;
}

export enum TournamentStatus {
  CREATED = 'CREATED',
  REGISTERING = 'REGISTERING',
  PREPARING = 'PREPARING',
  LATE_REGISTRATION = 'LATE_REGISTRATION',
  RUNNING = 'RUNNING',
  BREAK = 'BREAK',
  CLOSED = 'CLOSED',
  UNKNOWN = 'UNKNOWN'
}

// Payout structure and re-entry configurations
export interface TournamentPayoutInfo {
  type: 'PERCENTAGE' | 'FIXED';
  value: number;
}

interface BaseTournamentConfig {
  value: number;
  code: 'FREEZE_OUT' | 'REBUY' | 'REENTRY' | 'UNLIMITED';
}

// Tournament structure including blind schedule and payout structure
interface TournamentStructure {
  blindSchedule: BlindLevel[];
  payoutStructure: PayoutStructure;
}

export interface BlindLevel {
  blindId: string;
  level: number;
  sb: number;
  bb: number;
  ante: number;
  interval: number; // in minutes
  type: 'BREAK' | 'BLIND';
}

export interface PayoutStructure {
  numberOfMoneyIn: number;
  info: PayoutInfo[];
}

export interface PayoutInfo {
  minEntry: number;
  maxEntry: number;
  placesPaid: number;
}

// Prize pool and prize distribution
interface PrizePool {
  asset: PrizeAsset;
  pool: PrizeAmount[];
}

interface PrizeAsset {
  name: string;
  displayDecimal: number;
}

export interface PrizeAmount {
  id: string;
  tournamentId: string;
  minRank: number;
  maxRank: number;
  prizeAmount: string;
}

export interface TournamentPlayer {
  chips: number; // Number of chips the player has
  entryCount: number;
  flag: string; // URL or link to player's flag
  nickname: string;
  numberOfEntry: number; // Number of re-entries or entries
  rank: number; // Player's current rank
  shareCode?: string;
  stack: number; // Player’s stack in terms of big blinds
  userId: number;
  prizeAmount: string;
}

export interface TournamentSummary {
  myRank: number;
  playerStatus?: PlayerStatus;
  prizeInfo?: PrizeInfo;
  stackStatus: StackStatus;
}

export interface PrizeInfo {
  total: number;
  first: number;
  next: number;
  placesPaid: number;
}

export interface TournamentTables {
  tableId: string;
  shareCode: string;
  playersCnt: number;
  stack?: StackInfo;
}

interface PlayerStatus {
  live: number;
  unique: number;
  reEntry: number;
}

interface StackStatus {
  stacks: StackInfo;
  chips: StackInfo;
}

interface StackInfo {
  smallest: number;
  largest: number;
  average: number;
}
