import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

import DefaultLayout from 'src/components/layout/DefaultLayout';
import LandingScreen from 'src/pages/LandingScreen';
import TableScreen from 'src/pages/TableScreen';
import SettingScreen from '../pages/SettingsScreen';
import AuthScreen from '../pages/AuthScreen';
import ErrorTemplate from '../components/template/utils/ErrorTemplate';
import { ErrorTypes } from '../store/api/api';
import LobbyScreen from '../pages/LobbyScreen';
import PolicyScreen from '../pages/PolicyScreen';
import React from 'react';
import TournamentLobbyScreen from '../pages/TournamentLobbyScreen';
import TournamentDetailScreen from '../pages/TournamentDetailScreen';

const routers: RouteObject[] = [
  {
    path: '/', // 기본 언어 URL 없이 동작
    element: <DefaultLayout />, // 언어 설정 및 Wrapper
    children: [
      {
        path: '',
        element: <LandingScreen />
      },
      {
        path: 'settings',
        element: <SettingScreen />
      },
      {
        path: 'cashgame',
        element: <LobbyScreen />
      },
      {
        path: 'cashgame/table/:shareCode',
        element: <TableScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: 'policy',
        element: <PolicyScreen />
      },
      {
        path: 'blog',
        element: <PolicyScreen />
      },
      // {
      //   path: 'tournament',
      //   element: <TournamentLobbyScreen />
      // },
      // {
      //   path: 'tournament/:tournamentId',
      //   element: <TournamentDetailScreen />
      // },
      // {
      //   path: 'tournament/:tournamentId/table',
      //   element: <TableScreen />,
      //   errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      // },
      // {
      //   path: 'tournament/:tournamentId/table/:shareCode',
      //   element: <TableScreen />,
      //   errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      // },
      {
        path: 'auth/:result',
        element: <AuthScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },

      {
        path: '*',
        element: <Navigate to="/" replace />, // 잘못된 경로 기본 언어로 리다이렉트
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      }
    ]
  },
  {
    path: '/:lang', // 비기본 언어
    element: <DefaultLayout />, // 언어 감지 및 설정 Wrapper
    children: [
      {
        path: '',
        element: <LandingScreen />
      },
      {
        path: 'settings',
        element: <SettingScreen />
      },
      {
        path: 'cashgame',
        element: <LobbyScreen />
      },
      {
        path: 'cashgame/table/:shareCode',
        element: <TableScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: 'policy',
        element: <PolicyScreen />
      },
      // {
      //   path: 'tournament',
      //   element: <TournamentLobbyScreen />
      // },
      // {
      //   path: 'tournament/:tournamentId',
      //   element: <TournamentDetailScreen />
      // },
      // {
      //   path: 'tournament/:tournamentId/table',
      //   element: <TableScreen />,
      //   errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      // },
      // {
      //   path: 'tournament/:tournamentId/table/:shareCode',
      //   element: <TableScreen />,
      //   errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      // },
      {
        path: 'auth/:result',
        element: <AuthScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: '*',
        element: <Navigate to="/:lang" replace />, // 잘못된 경로 리다이렉트
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      }
    ]
  }
];

export default createBrowserRouter(routers);
